import { useEffect } from 'react';
import {
  FormTab,
  maxLength,
  required,
  TextInput,
  useRecordContext,
  useTranslate,
  number,
  minValue,
  maxValue,
  NumberInput,
  FormDataConsumer,
} from 'react-admin';
import { Box, Link, Tooltip } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';

import {
  Flex,
  ReachTextEditor,
  ResourceAutocompleteInput,
  ResourceInputNumber,
  ResourceMoneyInput,
  ResourceSwitcher,
  ResourceTab,
  ResourceTabbedForm,
  RowInfoBlock,
} from '@UI';
import { useIsMobile } from '@Hooks/useIsMobile';
import {
  accessPermission,
  isDiscountPriceSatisfactory,
  shouldBeEmptyRelatedToField,
  isEmptyAfterTrimmed,
  maxLengthExt,
  minNum,
  onlyLettersAndDigits,
  pointsToUnits,
  isInteger,
} from '@Helpers';
import { LinkedList } from '@Widgets/LinkedList/LinkedList';
import { FieldValues } from 'react-hook-form';

import { ResourceRoutes } from '../../../resourceRoutes';
import {
  DEFAULT_OFFER_FORM_VALUES,
  STATUS_CHOICES,
  SUPPLIER_CHOICES,
} from '../share/constants';
import { ImageGallery } from '../share/Tabs/ImageGallary/ImageGallery';
import { AdditionalProperties } from '../share/Tabs/AdditionalProperties/AdditionalProperties';
import { IN_STOCK_INFORMATION_LIST_COLUMNS, ResourceMeta } from './constants';
import { OfferFormToolbar } from './components/OfferFormToolbar';
import { LinkedProduct } from './components/LinkedProduct';
import { requiredOnActive, barcode } from './utils';

export const OfferForm = () => {
  const record = useRecordContext<CoreOffer>();
  const isMobile = useIsMobile();
  const translate = useTranslate();

  useEffect(() => {
    window.localStorage.setItem('currentProductId', `${record.productId}`);
  }, [record.id]);

  return (
    <>
      {record.productId !== null ? (
        <Flex p={2} justifyContent="center">
          <Link
            href={`#${ResourceRoutes.product.resourcePath}/${record.productId}/4`}
          >
            {translate('catalogue.pages.offers.labels.parentProduct', {
              id: record.productId,
            })}
          </Link>

          <Box mx={1}>→</Box>

          {translate('catalogue.pages.offers.labels.mobileCardCaption', {
            id: record.id,
          })}
        </Flex>
      ) : (
        <></>
      )}

      <ResourceTabbedForm
        record={record}
        defaultValues={DEFAULT_OFFER_FORM_VALUES}
        mutationMode="optimistic"
        toolbar={
          accessPermission.checkPermission('Catalogue', 'UD') ? (
            <OfferFormToolbar />
          ) : (
            <></>
          )
        }
      >
        <FormTab label="catalogue.pages.products.tabs.general">
          <Flex asColumn={isMobile} gap={1} fullWidth>
            <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
              <TextInput
                fullWidth
                name="name"
                source="name"
                label="catalogue.pages.offers.labels.name"
                validate={validateName}
              />

              <RowInfoBlock
                flex={1}
                fullWidth
                sx={{ paddingInline: 0 }}
                titleKey="catalogue.pages.products.labels.description"
              >
                <ReachTextEditor name="description" />
              </RowInfoBlock>

              <ResourceMoneyInput
                name="price"
                source="price"
                label="catalogue.pages.offers.labels.price"
                validate={validatePrice}
                fullWidth
              />

              <ResourceAutocompleteInput
                name="supplierPartnerCode"
                source="supplierPartnerCode"
                label="catalogue.pages.offers.labels.supplier"
                choices={SUPPLIER_CHOICES}
                validate={validateSupplierPartnerCode}
                fullWidth
              />

              <TextInput
                name="barcodes"
                source="barcodes"
                label="catalogue.pages.offers.labels.barcodes"
                fullWidth
                multiline
                validate={validateBarcodes}
              />

              {accessPermission.company?.hasGTINField && (
                <TextInput
                  name="gtin"
                  source="gtin"
                  label="catalogue.pages.offers.labels.gtin"
                  validate={validateGTIN}
                  fullWidth
                />
              )}

              <NumberInput
                defaultValue="0"
                source="sorting"
                name="sorting"
                label={
                  <Flex gap={1} alignItems="center">
                    <span>
                      {translate(
                        'catalogue.pages.products.labels.offerSorting'
                      )}
                      *
                    </span>
                    <Tooltip
                      title={translate(
                        'catalogue.pages.products.labels.offerSortingHelp'
                      )}
                      placement="top"
                    >
                      <InfoRounded fontSize="small" />
                    </Tooltip>
                  </Flex>
                }
                fullWidth
                validate={validateSorting}
              />
              {/* <Flex fullWidth gap={1}>
                <Flex width="50%">
                  <OfferGenerationProperty
                    type="size"
                    label="catalogue.pages.offers.labels.size"
                  />
                </Flex>

                <Flex width="50%">
                  <OfferGenerationProperty
                    type="color"
                    label="catalogue.pages.offers.labels.color"
                  />
                </Flex>
              </Flex> */}
            </RowInfoBlock>

            <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
              <ResourceAutocompleteInput
                name="status"
                source="status"
                label="catalogue.pages.offers.labels.status"
                optionText="name"
                optionValue="id"
                choices={STATUS_CHOICES}
                translateChoice
                fullWidth
                validate={validateStatus}
              />

              <TextInput
                name="code"
                source="code"
                label="catalogue.pages.offers.labels.code"
                disabled
                validate={validateCode}
                fullWidth
              />

              <ResourceMoneyInput
                name="discountPrice"
                source="discountPrice"
                label="catalogue.pages.offers.labels.discountPrice"
                validate={validateDiscountPrice}
                fullWidth
              />

              <ResourceSwitcher
                name="vatIncludes"
                labelKey="catalogue.pages.offers.labels.vatIncludes"
              />

              <ResourceInputNumber
                name="vatAmount"
                source="vatAmount"
                label="catalogue.pages.offers.labels.taxes"
                validate={validateVatAmount}
                fullWidth
              />

              <ResourceMoneyInput
                name="cost"
                source="cost"
                label="catalogue.pages.offers.labels.cost"
                validate={validateCost}
                fullWidth
              />

              {accessPermission.company?.isBackorderEnabled && (
                <>
                  <ResourceSwitcher
                    name="isBackorderAvailable"
                    labelKey="catalogue.pages.offers.labels.isBackorderAvailable"
                  />

                  <ResourceInputNumber
                    name="backorderLeadTime"
                    source="backorderLeadTime"
                    label="catalogue.pages.offers.labels.backorderLeadTime"
                    validate={validateBackorderLeadTime}
                    fullWidth
                  />
                </>
              )}

              {accessPermission.company?.isMultiBuyEnabled && (
                <>
                  <ResourceMoneyInput
                    name="minBasketDiscountPrice"
                    source="minBasketDiscountPrice"
                    label="catalogue.pages.offers.labels.minBasketDiscountPrice"
                    validate={validateMinBasketDiscountPrice}
                    fullWidth
                  />

                  <ResourceInputNumber
                    name="minBasketDiscountQuantity"
                    source="minBasketDiscountQuantity"
                    label="catalogue.pages.offers.labels.minBasketDiscountQuantity"
                    validate={validateMinBasketDiscountQuantity}
                    fullWidth
                  />
                </>
              )}

              <ResourceSwitcher
                name="isGift"
                labelKey="catalogue.pages.offers.labels.isGift"
              />

              <ResourceSwitcher
                name="countedTowardMinimumValueThreshold"
                translate="no"
                labelKey={
                  <Flex asColumn={false} gap={1}>
                    <span>
                      {translate(
                        'catalogue.pages.offers.labels.countedTowardMinimumValueThreshold'
                      )}
                    </span>
                    <Tooltip
                      placement="top"
                      title={translate(
                        'catalogue.pages.offers.messages.countedTowardMinimumValueThresholdHelp'
                      )}
                    >
                      <InfoRounded />
                    </Tooltip>
                  </Flex>
                }
              />

              <ResourceSwitcher
                labelKey={
                  <Flex asColumn={false} gap={1}>
                    <span>
                      {translate(
                        'catalogue.pages.offers.labels.canBePurchasedAlone'
                      )}
                    </span>
                    <Tooltip
                      placement="top"
                      title={translate(
                        'catalogue.pages.offers.messages.canBePurchasedAloneHelp'
                      )}
                    >
                      <InfoRounded />
                    </Tooltip>
                  </Flex>
                }
                translate="no"
                name="canBePurchasedAlone"
              />
            </RowInfoBlock>
          </Flex>
        </FormTab>

        <ResourceTab
          subscribedFields={['metadataTitle', 'metadataDescription']}
          label="catalogue.pages.categories.tabs.seo"
        >
          <Flex asColumn fullWidth>
            <RowInfoBlock flex={1}>
              <TextInput
                fullWidth
                name="metadataTitle"
                source="metadataTitle"
                label="catalogue.pages.products.labels.metadataTitle"
              />
              <TextInput
                fullWidth
                name="metadataDescription"
                source="metadataDescription"
                multiline
                rows={5}
                label="catalogue.pages.products.labels.metadataDescription"
              />
            </RowInfoBlock>
          </Flex>
        </ResourceTab>

        <ResourceTab
          label="catalogue.pages.products.tabs.additionalLanguages"
          subscribedFields={['nameAr', 'descriptionAr']}
        >
          <Flex asColumn fullWidth>
            <RowInfoBlock
              asColumn
              flex={1}
              fullWidth
              titleKey="catalogue.pages.products.labels.nameAr"
            >
              <TextInput
                fullWidth
                name="nameAr"
                source="nameAr"
                label="catalogue.pages.products.labels.nameAr"
                validate={maxLengthExt(255)}
              />
            </RowInfoBlock>
            <RowInfoBlock
              asColumn
              flex={1}
              fullWidth
              titleKey="catalogue.pages.products.labels.descriptionAr"
            >
              <ReachTextEditor name="descriptionAr" isRequired={false} />
            </RowInfoBlock>
          </Flex>
        </ResourceTab>

        <ResourceTab
          label="catalogue.pages.products.tabs.images"
          subscribedFields={['images']}
        >
          <FormDataConsumer>
            {({ formData }) => (
              <ImageGallery
                type="offers"
                imagesSource="images"
                isAdditionalRequired={formData.status === 'active'}
              />
            )}
          </FormDataConsumer>
        </ResourceTab>

        <ResourceTab
          label="catalogue.pages.products.tabs.additional"
          subscribedFields={['properties']}
        >
          <AdditionalProperties source="properties" />
        </ResourceTab>

        <FormTab label="catalogue.pages.products.tabs.inStockInformation">
          <LinkedList<CoreOffer>
            target={ResourceMeta.inStockInformation}
            cols={IN_STOCK_INFORMATION_LIST_COLUMNS}
            identifier="code"
          />
        </FormTab>

        <ResourceTab
          subscribedFields={['productId']}
          label="catalogue.pages.products.tabs.linkedProduct"
        >
          <LinkedProduct />
        </ResourceTab>
      </ResourceTabbedForm>
    </>
  );
};

const validateName = [required(), isEmptyAfterTrimmed(), maxLengthExt(255)];
const validateCode = [requiredOnActive()];
const validateStatus = [required()];
const validatePrice = [required(), minNum(parseFloat(pointsToUnits(1)))];
const validateDiscountPrice = [
  minNum(parseFloat(pointsToUnits(1))),
  isDiscountPriceSatisfactory(),
  shouldBeEmptyRelatedToField('minBasketDiscountPrice'),
];
const validateVatAmount = [requiredOnActive(), minNum(0)];
const validateBackorderLeadTime = [
  number(),
  minNum(0),
  isInteger(),
  (value: string, fieldValues: FieldValues) => {
    const { isBackorderAvailable } = fieldValues;

    if (isBackorderAvailable && !value) {
      return 'catalogue.pages.offers.errors.forceBackorderLeadTime';
    }
  },
];
const validateBarcodes = [
  requiredOnActive(),
  maxLength(15),
  onlyLettersAndDigits(),
  barcode(),
];
const validateSorting = [requiredOnActive(), isInteger(), maxValue(2147483647)];
const validateGTIN = [maxLength(15), onlyLettersAndDigits()];
const validateCost = [requiredOnActive(), minNum(parseFloat(pointsToUnits(1)))];
const validateMinBasketDiscountPrice = [
  number(),
  minValue(parseFloat(pointsToUnits(1))),
  isDiscountPriceSatisfactory(),
  shouldBeEmptyRelatedToField('discountPrice'),
  (value: string, values: CoreOffer) => {
    const { minBasketDiscountQuantity } = values;

    if (!value && minBasketDiscountQuantity) {
      return 'catalogue.pages.offers.errors.minBasketDiscountPriceRequiredForQuantity';
    }
  },
];
const validateSupplierPartnerCode = [requiredOnActive()];

const validateMinBasketDiscountQuantity = [
  number(),
  isInteger(),
  minValue(1),
  maxValue(1000),
  (value: string, values: CoreOffer) => {
    const { minBasketDiscountPrice } = values;

    if (minBasketDiscountPrice && !value) {
      return 'catalogue.pages.offers.errors.minBasketDiscountQuantityRequiredForPrice';
    }
  },
];
