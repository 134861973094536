import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { STOCKS_LIST_COLUMNS } from '../share/constants';
import { minLength, TopToolbar, useGetList, useTranslate } from 'react-admin';
import { Filter } from '@Widgets/ResourceList/interface';
import { UpdateListByFileButton } from '@UI';
import { apiUrl } from './constants';
import React from 'react';
import { ResourceRoutes } from '../../../resourceRoutes';
import { ExportXLSXButton, accessPermission } from '@Helpers';
import { CompanyName } from '@Helpers/AccessPermissions/interface';

const ListActions = () => (
  <TopToolbar>
    <ExportXLSXButton
      url={`${apiUrl}/export`}
      filename="stocks"
      filterFields={['search', 'warehouseCode']}
    />

    {accessPermission.companyName !== CompanyName.Baqal && (
      <UpdateListByFileButton
        successMessage="inventory.pages.stocks.messages.successImport"
        errorMessage="inventory.pages.stocks.errors.errorImport"
        urlOfXLSXExample={`${apiUrl}/import/example`}
      />
    )}
  </TopToolbar>
);

export const ListPage = () => {
  const translate = useTranslate();
  const { data: warehouseList = [], isLoading } = useGetList<CoreWarehouse>(
    ResourceRoutes.warehouse.resourcePath,
    {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: 'createdAt',
        order: 'DESC',
      },
    }
  );

  const listFilters: Filter[] = [
    {
      source: 'search',
      type: 'search',
      validate: minLength(
        2,
        translate('inventory.pages.stocks.errors.min2chars')
      ),
    },
    {
      type: 'choose',
      source: 'warehouseCode',
      label: 'inventory.pages.stocks.labels.warehouseCode',
      optionText: 'code',
      optionValue: 'code',
      isLoading: isLoading,
      choices: warehouseList,
    },
  ];

  return (
    <>
      <ResourceList<CoreStock>
        columns={STOCKS_LIST_COLUMNS}
        actions={<ListActions />}
        showDeleteButton={false}
        getItemCardCaption={(record) =>
          translate('inventory.pages.stocks.labels.mobileCardCaption', {
            id: record.id,
          })
        }
        defaultSort={{
          // FIXME: 'createdAt' doesn't exist in the CoreStock
          // @ts-ignore
          field: 'createdAt',
          order: 'DESC',
        }}
        filters={listFilters}
        perPage={25}
        isInteractive={false}
        empty={false}
      />
    </>
  );
};
