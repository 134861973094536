import { styled } from '@mui/material/styles';
import { MarkerProps } from './interface';

export const MarkerStyled = styled('div')<MarkerProps>`
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  left: -12px;
  top: -24px;
`;
