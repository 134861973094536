import { useRecordContext } from 'react-admin';
import { Typography, Link } from '@mui/material';
import { UserInfoProps } from '../../interface';
import { Flex } from '@UI';
import { useIsMobile } from '@Hooks/useIsMobile';
import { formatPhone } from '@Helpers';

import { Root, UserInfoClasses } from './styles';

export const UserInfo = ({ type }: UserInfoProps) => {
  const record = useRecordContext<CoreOrder>();
  const isMobile = useIsMobile();

  if (type === 'address') {
    if (record.address) {
      return (
        <Link
          fontSize={14}
          target="_blank"
          href={`https://maps.google.com/?q=${record.address.latitude},${record.address.longitude}`}
        >
          {record.address.address_1}
        </Link>
      );
    } else return <></>;
  }

  const component = (
    <Root>
      <Typography fontSize={14}>{record.recipient.full_name}</Typography>

      <Typography fontSize={14} noWrap className={UserInfoClasses.phone}>
        {formatPhone(record.recipient.phone)}
      </Typography>
    </Root>
  );

  return isMobile ? <Flex asColumn>{component}</Flex> : component;
};
