import { FILTERS, LIST_COLUMNS } from '../constants';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';

export const ListPage = () => {
  return (
    <ResourceList<CorePromocode>
      filters={FILTERS}
      columns={LIST_COLUMNS}
      showDeleteButton
      getItemCardCaption={() => ''}
      empty={false}
    />
  );
};
