import { useRecordContext, useTranslate } from 'react-admin';

const StatusColumn = () => {
  const { kycVerificationStatus } = useRecordContext<CoreCustomer>();
  const translate = useTranslate();

  return (
    <>
      {translate(
        `customers.pages.customers.kycVerification.${kycVerificationStatus}`
      )}
    </>
  );
};

export default StatusColumn;
