import {
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteResult,
  GetListResult,
  GetOneResult,
  HttpError,
  RaRecord,
  UpdateResult,
} from 'react-admin';
import { A, D, pipe, S } from '@mobily/ts-belt';

import { Icon } from '@UI';

import { pointsToUnits, unitsToPoints } from '@Helpers';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';

import { CommonCrud, PluginResourceController } from '@PluginBase';

import { ResourceRoutes } from '../../../resourceRoutes';

import { ListPage } from './ListPage';
import {
  apiUrl,
  BundlePageData,
  UpdateBundleParams,
  UpdateMeta,
} from './constants';
import { BundleForm } from './BundleForm';
import { ValidationError } from '@PluginManager/base/AbstractApi/ValidationError';

const bundlesCrud = new CommonCrud(apiUrl, { isNewSorting: true });

const handleOfferUpdateError = (error: unknown) => {
  if (!(error instanceof ValidationError)) {
    return;
  }

  throw new HttpError(error.message, 422, {
    errors: error.fields,
  });
};

const sortFn = (a: RaRecord, b: RaRecord): number => {
  if (a.id === b.id) return 0;

  return a.id > b.id ? 1 : -1;
};

const normalizeOffer = <T extends BundlePlaceOfferRequestDto>(
  placeOffer: T
): T => {
  const price =
    placeOffer.price === undefined ||
    placeOffer.price === null ||
    Number(placeOffer.price) === 0
      ? undefined
      : Number(unitsToPoints(placeOffer.price));

  return {
    ...placeOffer,
    price,
  };
};

const BundlesController = new PluginResourceController({
  menuItem: {
    caption: {
      translationKey: 'catalogue.pages.bundles.caption',
    },
    route: ResourceRoutes.bundles.routePath,
    icon: <Icon type="bundle" />,
  },
  resourceRoute: {
    name: ResourceRoutes.bundles.resourcePath,
    list: ListPage,
    create: (
      <ResourceItemPage type="create" includeForm={false}>
        <BundleForm />
      </ResourceItemPage>
    ),
    edit: (
      <ResourceItemPage type="edit" includeForm={false} redirect={false}>
        <BundleForm />
      </ResourceItemPage>
    ),
  },

  dataProvider: {
    getList: async (resource, params): Promise<GetListResult> => {
      return bundlesCrud.list(params);
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      if (params.id === undefined || params.id === null)
        return { data: { id: 0 } };

      const result = await bundlesCrud.getOne<{ data: CoreBundle }>(params);
      const resultData: CoreBundle = result?.data as CoreBundle;

      const preparedResultData: BundlePageData = {
        ...resultData,
        bundlePlaces: resultData.bundlePlaces.sort(sortFn).map((place) => ({
          ...place,
          bundlePlaceOffers: place.bundlePlaceOffers
            .sort(sortFn)
            .map((offer) => {
              const price =
                !offer.price || Number(offer.price) === 0
                  ? pointsToUnits(offer.offer.price)
                  : pointsToUnits(offer.price);

              return {
                ...offer,
                price,
                offer: {
                  ...offer.offer,
                  price: pointsToUnits(offer.offer.price),
                  discountPrice:
                    offer.offer.discountPrice !== null
                      ? pointsToUnits(offer.offer.discountPrice)
                      : null,
                },
              };
            }),
        })),
      };

      return {
        data: preparedResultData,
      };
    },
    create: async (resource, params): Promise<CreateResult> => {
      const paramsData = D.deleteKey(
        params.data as CoreCreateBundleRequest,
        'sorting'
      );
      const dataToSend: CoreCreateBundleRequest = {
        ...paramsData,
        bundlePlaces: [],
      };
      const response = await bundlesCrud.create({
        data: dataToSend,
      });

      return {
        data: (response as any)?.data || {},
      };
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      await bundlesCrud.delete(params);

      return {
        data: {},
      };
    },
    deleteMany: async (
      resource: string,
      { ids, meta }: DeleteManyParams
    ): Promise<DeleteManyResult> => {
      const pageData: BundlePageData = meta.pageData;
      const idsOfRemovingPlaces = ids.filter((id) => !`${id}`.includes('_'));
      const offerIdsByPlaceIds: Record<string, string[]> = pipe(
        ids,
        A.filter((id) => !idsOfRemovingPlaces.includes(id)),
        A.map(S.split('_')),
        A.filter(([placeId]) => !idsOfRemovingPlaces.includes(placeId)),
        A.groupBy(([placeId]) => placeId),
        D.map((pairs) => pairs?.map((offerId) => offerId[1]) || [])
      );

      pageData.bundlePlaces = pageData.bundlePlaces
        .filter((place) => !idsOfRemovingPlaces.includes(String(place.id)))
        .map((place) => {
          const offerIdsInPlace = offerIdsByPlaceIds[place.id];

          if (offerIdsInPlace) {
            place.bundlePlaceOffers = place.bundlePlaceOffers.filter(
              (offer) => !offerIdsInPlace.includes(`${offer.offerId}`)
            );
          }

          return {
            ...place,
            bundlePlaceOffers: place.bundlePlaceOffers.map(normalizeOffer),
          };
        });

      try {
        await bundlesCrud.update({ id: pageData.id, data: pageData });
      } catch (error) {
        handleOfferUpdateError(error);
      }

      return {
        data: [],
      };
    },
    update: async (
      _ignored,
      params: UpdateBundleParams
    ): Promise<UpdateResult> => {
      const meta: UpdateMeta = params.meta || {};
      const paramsNewData = { ...params.data };
      const allBundlePlaces = params.data.bundlePlaces
        ? params.data.bundlePlaces
        : params.previousData.bundlePlaces ?? [];

      if (meta.offerIdsToAdd) {
        const addedOffers: CoreBundlePlaceOfferRequest[] =
          meta.offerIdsToAdd.map((offerId) => ({
            offerId,
          }));
        const bundlePlaceToEdit = allBundlePlaces.find(
          (place: any) => place.id === meta.editedPlaceId
        );

        // TODO: Remove ts-ignore
        // @ts-ignore
        bundlePlaceToEdit?.bundlePlaceOffers.push(...addedOffers);
      } else if (meta.addPlace) {
        // TODO: Remove ts-ignore
        // @ts-ignore
        allBundlePlaces.push({
          bundlePlaceOffers: [],
        });
      }

      const dataToSend: CorePatchBundleRequestExtended = {
        ...paramsNewData,
        id: params.id,
        bundlePlaces: allBundlePlaces.map((place) => {
          if (!place.bundlePlaceOffers) {
            return place;
          }

          return {
            ...place,
            bundlePlaceOffers: place.bundlePlaceOffers.map(normalizeOffer),
          };
        }),
        updatedAt: new Date().toISOString(),
      };

      try {
        await bundlesCrud.update(
          {
            id: params.id,
            data: D.deleteKey(dataToSend, 'sorting'),
          },
          {
            'Content-Type': 'application/json',
          }
        );
      } catch (error) {
        handleOfferUpdateError(error);
      }

      return {
        data: {
          ...dataToSend,
          bundlePlaces: allBundlePlaces,
        },
      };
    },
  },
});

export default BundlesController;
