import { useNavigate } from 'react-router';
import { useTranslate } from 'react-admin';
import { useTheme } from '@mui/material/styles';

import { Icon, Flex } from '@UI';

import { Root } from './styles';

export const ResourceGoToListButton = ({ resource }: { resource: string }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const translate = useTranslate();

  function onBackHandler() {
    navigate(resource);
  }

  return (
    <Flex py={1} bgcolor={theme.palette.background.default}>
      <Root
        color="secondary"
        size="small"
        onClick={onBackHandler}
        startIcon={
          <Icon
            type="arrowDown"
            rotate={theme.direction === 'ltr' ? 90 : -90}
          />
        }
      >
        {translate('goToList')}
      </Root>
    </Flex>
  );
};
