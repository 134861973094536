import { styled } from '@mui/material/styles';
import { TableRow, TableCell } from '@mui/material';

export const TableRowStyled = styled(TableRow)<{ $isActive: boolean }>`
  cursor: pointer;

  ${({ $isActive }) => `background-color: ${$isActive ? '#263534' : '#242d37'}`}
`;

export const IndexTableCellStyled = styled(TableCell)<{
  $level: number;
  $indexInGroup: number;
  $isExpandable: boolean;
}>`
  position: relative;
  padding-inline-start: ${({ $level }) =>
    $level ? 15 * $level + 10 : 10}px !important;

  ${({ $level, $indexInGroup, $isExpandable }) =>
    $level
      ? `
        &:before {
          content: '';
          border-left: 1px solid;
          border-bottom: 1px solid;
          display: block;
          position: absolute;
          pointer-events: none;
          bottom: 50%;
          top: ${$indexInGroup * -32 - 12}px;
          width: ${$isExpandable ? 7 : 21}px;
          left: ${$level === 1 ? 24 : $level * 15 + 9}px;
        }
      `
      : ''}
`;
