import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { LIST_FILTERS } from '../share/constants';
import { useTranslate, FilterButton } from 'react-admin';
import { Flex } from '@UI';
import { PRODUCT_LIST_COLUMNS } from './constants';

export const ListPage = () => {
  const translate = useTranslate();

  return (
    <ResourceList<CoreProduct>
      columns={PRODUCT_LIST_COLUMNS}
      showDeleteButton={false}
      actions={
        <Flex alignItems="center">
          <FilterButton />
        </Flex>
      }
      bulkActionButtons={false}
      disableSyncWithLocation={false}
      filters={LIST_FILTERS}
      defaultSort={{
        field: 'createdAt',
        order: 'DESC',
      }}
      empty={false}
      getItemCardCaption={(record) =>
        translate('catalogue.pages.products.labels.mobileCardCaption', {
          id: record.id,
        })
      }
    />
  );
};
