import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { LIST_FILTERS, OFFER_LIST_COLUMNS } from '../share/constants';
import { useTranslate } from 'react-admin';

import { ListActions } from './components/ListActions';

export const ListPage = () => {
  const translate = useTranslate();

  return (
    <ResourceList<CoreOffer>
      columns={OFFER_LIST_COLUMNS}
      actions={<ListActions />}
      showDeleteButton={false}
      bulkActionButtons={false}
      disableSyncWithLocation={false}
      empty={false}
      filters={LIST_FILTERS}
      getItemCardCaption={(record) =>
        translate('catalogue.pages.offers.labels.mobileCardCaption', {
          id: record.id,
        })
      }
    />
  );
};
