import { useRecordContext } from 'react-admin';

import { Root } from './styles';

const PhoneColumn = () => {
  const { phone } = useRecordContext();

  return <Root>{phone}</Root>;
};

export default PhoneColumn;
