import { useState } from 'react';
import { useController } from 'react-hook-form';
import { Button, Identifier, useTranslate } from 'react-admin';
import { Box, Typography, IconButton, Drawer } from '@mui/material';
import { Add, Remove, Close } from '@mui/icons-material';

import { Flex } from '@UI';

import Warehouses from './components/Warehouses';
import RemoveFromBlacklistAction from './components/RemoveFromBlacklistAction';
import EmptyBlacklist from './components/EmptyBlacklist';
import { WarehousesBlacklistWrapper } from './styled';

const Blacklist = (): JSX.Element => {
  const t = useTranslate();
  const { field: blacklistField } = useController({
    name: 'warehouseBlacklist',
  });

  const [selectedToRemove, setSelectedToRemove] = useState<Identifier[]>([]);
  const [selectedToAdd, setSelectedToAdd] = useState<Identifier[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleRemoveFromBlacklist = (): void => {
    const value = blacklistField.value.filter(
      (item: string) => !selectedToRemove.includes(item)
    );

    blacklistField.onChange(value);
    setSelectedToRemove([]);
  };

  const handleSelectToAddWarehouses = (selectedIds: Identifier[]): void => {
    setSelectedToAdd(selectedIds);
  };

  const handleSelectToRemoveWarehouses = (selectedIds: Identifier[]): void => {
    setSelectedToRemove(selectedIds);
  };

  const handleAddSelectedWarehousesToBlacklist = (): void => {
    const { value = [], onChange } = blacklistField;

    onChange([...value, ...selectedToAdd]);
    setIsDrawerOpen(false);
    setSelectedToAdd([]);
  };

  const handleRemoveSelectedWarehousesFromBlacklist = (): void => {
    const { onChange, value = [] } = blacklistField;

    const newValue = value.filter(
      (stored: Identifier) => !selectedToRemove.includes(stored)
    );

    onChange(newValue);
    setSelectedToRemove([]);
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleClickDeletionTableRow = (id: Identifier): boolean => {
    handleSelectToRemoveWarehouses(
      selectedToRemove.includes(id)
        ? selectedToRemove.filter((item) => item !== id)
        : [...selectedToRemove, id]
    );

    return false;
  };

  const handleClickDrawerTableRow = (id: Identifier): boolean => {
    handleSelectToAddWarehouses(
      selectedToAdd.includes(id)
        ? selectedToAdd.filter((item) => item !== id)
        : [...selectedToAdd, id]
    );

    return false;
  };

  return (
    <WarehousesBlacklistWrapper>
      <Warehouses
        showFilters={false}
        actions={
          <Flex sx={{ mb: 2 }} gap={2}>
            <Button
              label={t('catalogue.pages.categories.blacklist.buttons.add')}
              variant="contained"
              onClick={handleDrawerOpen}
            >
              <Add />
            </Button>
            <Button
              label={t('catalogue.pages.categories.blacklist.buttons.remove')}
              variant="contained"
              color="secondary"
              disabled={!selectedToRemove.length}
              onClick={handleRemoveSelectedWarehousesFromBlacklist}
            >
              <Remove />
            </Button>
          </Flex>
        }
        bulkActionButtons={
          <RemoveFromBlacklistAction
            onRemove={handleRemoveFromBlacklist}
            label={t('catalogue.pages.categories.blacklist.buttons.remove')}
          />
        }
        perPage={500 /* Just load all the data from api */}
        pagination={false}
        selectedIds={selectedToRemove}
        onSelect={handleSelectToRemoveWarehouses}
        rowClick={handleClickDeletionTableRow}
        blacklist={blacklistField.value || []}
        empty={<EmptyBlacklist onAdd={handleDrawerOpen} />}
      />

      <Drawer
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: { width: 1200 },
        }}
        anchor="right"
      >
        <Flex justifyContent="space-between" sx={{ p: 2 }}>
          <Typography variant="h4">
            {t('catalogue.pages.categories.blacklist.labels.addToBlacklist')}
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <Close />
          </IconButton>
        </Flex>
        <Box sx={{ px: 2 }}>
          <Warehouses
            showFilters
            bulkActionButtons={<></>}
            selectedIds={selectedToAdd}
            onSelect={handleSelectToAddWarehouses}
            rowClick={handleClickDrawerTableRow}
            isRowSelectable={(record) =>
              !blacklistField.value?.includes(record.id)
            }
          />

          <Flex justifyContent="flex-end" alignItems="center">
            <Button
              variant="contained"
              size="medium"
              label={t('catalogue.pages.categories.blacklist.buttons.add')}
              disabled={!selectedToAdd.length}
              onClick={handleAddSelectedWarehousesToBlacklist}
            />
          </Flex>
        </Box>
      </Drawer>
    </WarehousesBlacklistWrapper>
  );
};

export default Blacklist;
