import { styled } from '@mui/material/styles';
import { RadioButtonGroupInput, CheckboxGroupInput } from 'react-admin';

export const RadioButtonGroupStyled = styled(RadioButtonGroupInput)`
  .RaRadioButtonGroupInput-label {
    ${({ theme }) =>
      theme.direction === 'rtl' ? `transform-origin: top left;` : ''}
  }
`;

export const CheckboxGroupInputStyled = styled(CheckboxGroupInput)`
  .RaCheckboxGroupInput-label {
    ${({ theme }) =>
      theme.direction === 'rtl' ? `transform-origin: top left;` : ''}
  }
`;
