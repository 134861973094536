import { styled } from '@mui/material/styles';

export const FeedsPointStyled = styled('div')<{
  color: string;
}>`
  min-width: 20px;
  width: 20px;
  height: 20px;
  background: ${({ color }) => color};
  border-radius: 100px;
  margin-inline-end: 8px;
  z-index: 2;
`;

export const FeedsContainedStyled = styled('div')<{
  color: string;
}>`
  position: relative;
  ::before {
    background: ${({ color }) => color};
    bottom: 0;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: 2px;
    left: 25px;
  }
`;

export const PreviewWrapper = styled('div')`
  position: relative;

  .bundleBadge {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 115px;
    height: 40px;
    border-radius: 50% 0 50% 0;
    background: ${({ theme }) => theme.palette.success.main};
    color: ${({ theme }) => theme.palette.success.contrastText};
  }
`;
