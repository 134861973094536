import { ListColumn } from '@Widgets/ResourceList/interface';

import { AdditionalCategoriesColumn } from './ListPage/AdditionalCategoriesColumn';
import { IsDefaultColumn } from './ListPage/IsDefaultColumn';
import { PropertyNameColumn } from './ListPage/PropertyNameColumn';
import { PropertyTypeColumn } from './ListPage/PropertyTypeColumn';

export const LIST_COLUMNS: ListColumn<CoreProperty>[] = [
  { source: 'id', label: 'settings.pages.properties.labels.id', type: 'text' },
  {
    source: 'name',
    label: 'settings.pages.properties.labels.name',
    type: 'custom',
    customComponent: <PropertyNameColumn key="name" />,
  },
  {
    source: 'code',
    label: 'settings.pages.properties.labels.code',
    type: 'text',
    sortable: false,
  },
  {
    source: 'type',
    label: 'settings.pages.properties.labels.type',
    type: 'custom',
    customComponent: <PropertyTypeColumn />,
    sortable: false,
  },
  {
    source: 'sorting',
    label: 'settings.pages.properties.labels.sorting',
    type: 'text',
  },
  {
    source: 'isActive',
    label: 'settings.pages.properties.labels.isActive',
    type: 'boolean',
    sortable: false,
  },
  {
    source: 'isCategoryDefault',
    label: 'settings.pages.properties.labels.categoryDefault',
    type: 'boolean',
    sortable: false,
  },
  {
    source: 'isProductDefault',
    label: 'settings.pages.properties.labels.productDefault',
    type: 'boolean',
    sortable: false,
  },
];

export const FILTERS_COLUMNS: ListColumn<CoreFilterProperty>[] = [
  {
    source: 'additionalPropertyId',
    label: 'settings.pages.properties.labels.id',
    type: 'text',
    width: 40,
  },
  {
    // @ts-ignore
    source: 'additionalProperty.name',
    label: 'settings.pages.properties.labels.name',
    type: 'text',
    width: 350,
  },
  {
    source: 'isDefault',
    label: 'settings.pages.properties.labels.isDefault',
    type: 'custom',
    customComponent: <IsDefaultColumn />,
    sortable: false,
  },
  {
    source: 'categories',
    label: 'settings.pages.properties.labels.listOfCategories',
    type: 'custom',
    customComponent: <AdditionalCategoriesColumn />,
    sortable: false,
  },
];

export const PROPERTY_TYPE_CHOICES: SelectOptionTyped<CorePropertyType>[] = [
  { name: 'settings.pages.properties.labels.types.text', id: 'text' },
  { name: 'settings.pages.properties.labels.types.number', id: 'number' },
  { name: 'settings.pages.properties.labels.types.textarea', id: 'textarea' },
  {
    name: 'settings.pages.properties.labels.types.checkboxGroup',
    id: 'checkboxGroup',
  },
  { name: 'settings.pages.properties.labels.types.selector', id: 'selector' },
  { name: 'settings.pages.properties.labels.types.date', id: 'date' },
  { name: 'settings.pages.properties.labels.types.time', id: 'time' },
  { name: 'settings.pages.properties.labels.types.dateTime', id: 'dateTime' },
  {
    name: 'settings.pages.properties.labels.types.radioGroup',
    id: 'radioGroup',
  },
  { name: 'settings.pages.properties.labels.types.toggle', id: 'toggle' },
  { name: 'settings.pages.properties.labels.types.tagList', id: 'tagList' },
  { name: 'settings.pages.properties.labels.types.color', id: 'color' },
  { name: 'settings.pages.properties.labels.types.file', id: 'file' },
  { name: 'settings.pages.properties.labels.types.image', id: 'image' },
  { name: 'settings.pages.properties.labels.types.colorList', id: 'colorList' },
  { name: 'settings.pages.properties.labels.types.sizeList', id: 'sizeList' },
  { name: 'settings.pages.properties.labels.types.badge', id: 'badge' },
];

export const DEFAULT_FORM_VALUES: Omit<
  CoreProperty,
  'id' | 'isOfferGenerator'
> = {
  code: '',
  description: '',
  isActive: false,
  isCategoryDefault: false,
  isProductDefault: false,
  isOfferVariants: false,
  isTechnic: false,
  name: '',
  sorting: undefined as unknown as number,
  type: 'text',
  choices: [],
  showExpanded: false,
  locales: [
    {
      language: 'ar',
      name: '',
      description: '',
      choices: [],
    },
  ],
};

export const PROPERTIES_COLUMNS = [
  {
    field: 'value',
    headerName: 'settings.pages.properties.labels.propertyValues.value',
  },
  {
    field: 'label',
    headerName: 'settings.pages.properties.labels.propertyValues.label',
  },
  {
    field: 'sorting',
    headerName: 'settings.pages.properties.labels.propertyValues.sorting',
  },
];

export const LISTED_TYPES: CorePropertyType[] = [
  'tagList',
  'checkboxGroup',
  'selector',
  'radioGroup',
  'colorList',
  'sizeList',
];
