import { ReactNode } from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider as EmotionCacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const CacheProvider = ({ children }: { children: ReactNode }) => (
  <EmotionCacheProvider value={cacheRtl}>{children}</EmotionCacheProvider>
);

export default CacheProvider;
