import { Identifier, useTranslate } from 'react-admin';
import { useState } from 'react';

import { Filter } from '@Widgets/ResourceList/interface';

import {
  OFFER_LIST_COLUMNS,
  STATUS_CHOICES,
} from '@Plugins/Catalogue/resources/share/constants';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

import { ResourceListStyled } from './styles';
import { SelectOffersPopupContentProps } from './interfaces';

const SelectOffersPopupContent = ({
  selectedOffers,
  onSelect,
  isRowSelectable,
  columns = OFFER_LIST_COLUMNS,
  filters = LIST_FILTERS,
  disabled,
}: SelectOffersPopupContentProps) => {
  const translate = useTranslate();

  const [selectedIds, setSelectedIds] = useState<number[]>(
    selectedOffers?.map((item) => item.id) ?? []
  );

  const handleToggleItem = (id: number): void => {
    if (!isRowSelectable(id) || disabled) {
      return;
    }

    setSelectedIds(
      selectedIds.includes(id)
        ? selectedIds.filter((item) => item !== id)
        : [...selectedIds, id]
    );
  };

  const handleRowClick = (id: Identifier): boolean => {
    if (!isRowSelectable(Number(id)) || disabled) {
      return true;
    }

    setSelectedIds(
      selectedIds.includes(Number(id))
        ? selectedIds.filter((selectedId) => Number(selectedId) !== Number(id))
        : [...selectedIds, Number(id)]
    );

    return true;
  };

  return (
    <ResourceListStyled<CoreOffer>
      isRowSelectable={(record) => isRowSelectable(record.id)}
      columns={columns}
      resource={ResourceRoutes.offer.resourcePath}
      actions={false}
      filters={filters}
      rowClick={handleRowClick}
      onToggleItem={handleToggleItem}
      onSelectedInstanceChange={onSelect}
      selectedIds={selectedIds}
      getItemCardCaption={(record) =>
        translate('catalogue.pages.offers.labels.mobileCardCaption', {
          id: record.id,
        })
      }
      bulkActionButtons={<></>}
      heightOffset={340}
      overrideRowComponent
    />
  );
};

const LIST_FILTERS: Filter[] = [
  { source: 'search', type: 'search' },
  {
    source: 'status',
    label: 'catalogue.pages.products.labels.status',
    type: 'choose',
    choices: STATUS_CHOICES,
    emptyText: 'catalogue.pages.products.labels.allStatuses',
  },
];

export default SelectOffersPopupContent;
