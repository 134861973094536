import React from 'react';
import {
  required,
  TextInput,
  useCreateContext,
  useRecordContext,
  useSaveContext,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { D } from '@mobily/ts-belt';

import {
  Flex,
  ReachTextEditor,
  ResourceAutocompleteInput,
  ResourceTab,
  ResourceTabbedForm,
  RowInfoBlock,
} from '@UI';

import { isEmptyAfterTrimmed, maxLengthExt } from '@Helpers';

import { useIsMobile } from '@Hooks/useIsMobile';
import { useGroupedCategoryList } from '@Hooks/useGroupedCategoryList';
import { CategorySelect } from '@Plugins/Catalogue/resources/Products/components/CategorySelect';

import { STATUS_CHOICES } from '../share/constants';

import { BUNDLE_TABS_SUBSCRIBED_FIELDS } from './constants';
import { BundleSettingsTab } from './BundleSettingsTab';
import { ImagesTab } from './ImagesTab';

const validateName = [required(), isEmptyAfterTrimmed(), maxLengthExt(255)];
const validateArName = [isEmptyAfterTrimmed(), maxLengthExt(255)];

const getKeysByTemplate = (keys: string[], template: string) =>
  keys.filter((key) => key.startsWith(template));

const transformFormData = (formData: CorePatchBundleRequestExtended) => {
  const formDataKeys = Object.keys(formData);
  const bundleOfferPriceKeys = getKeysByTemplate(
    formDataKeys,
    'bundleOfferPrice_'
  );
  const discountKeys = getKeysByTemplate(formDataKeys, 'discount_');
  const originalOfferPriceKeys = getKeysByTemplate(
    formDataKeys,
    'originalOfferPrice_'
  );
  const allGeneratedOfferFieldKeys = [
    ...bundleOfferPriceKeys,
    ...discountKeys,
    ...originalOfferPriceKeys,
  ];

  return D.deleteKeys(formData, [
    ...allGeneratedOfferFieldKeys,
    'bundleTableRows',
  ]);
};

export const BundleForm = () => {
  const { resource: resourceForCreatePage } = useCreateContext();
  const isEditPage = !resourceForCreatePage;
  const isMobile = useIsMobile();
  const groupedCategoryList = useGroupedCategoryList();
  const { save } = useSaveContext();
  const recordContext = useRecordContext<CorePatchBundleRequestExtended>();

  const onSubmit = (record: FieldValues) => {
    save?.(transformFormData(record as CorePatchBundleRequestExtended));
  };

  return (
    <ResourceTabbedForm
      onSubmit={onSubmit}
      record={recordContext}
      mode="all"
      reValidateMode="onChange"
      syncWithLocation
    >
      <ResourceTab
        label="catalogue.pages.bundles.tabs.general"
        subscribedFields={BUNDLE_TABS_SUBSCRIBED_FIELDS.general}
      >
        <Flex asColumn={isMobile} gap={1} fullWidth>
          <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
            <TextInput
              fullWidth
              name="name"
              source="name"
              label="catalogue.pages.bundles.labels.name"
              validate={validateName}
            />

            <ResourceAutocompleteInput
              name="status"
              source="status"
              label="catalogue.pages.bundles.labels.status"
              optionText="name"
              optionValue="id"
              choices={STATUS_CHOICES}
              translateChoice
              fullWidth
              validate={required()}
            />

            <CategorySelect
              source="categoryId"
              options={groupedCategoryList}
              validate={validateCategoryId}
            />
          </RowInfoBlock>

          <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
            <CategorySelect
              source="additionalCategoryIds"
              label="catalogue.pages.bundles.labels.additionalCategories"
              options={groupedCategoryList}
              multi
            />
          </RowInfoBlock>
        </Flex>
      </ResourceTab>

      <ResourceTab
        label="catalogue.pages.bundles.tabs.description"
        subscribedFields={BUNDLE_TABS_SUBSCRIBED_FIELDS.description}
      >
        <Flex asColumn fullWidth>
          <RowInfoBlock flex={1}>
            <ReachTextEditor name="description" />
          </RowInfoBlock>
        </Flex>
      </ResourceTab>

      <ResourceTab
        label="catalogue.pages.bundles.tabs.additionalLanguages"
        subscribedFields={BUNDLE_TABS_SUBSCRIBED_FIELDS.description}
      >
        <Flex asColumn fullWidth>
          <RowInfoBlock
            flex={1}
            titleKey="catalogue.pages.bundles.labels.nameAr"
          >
            <TextInput
              fullWidth
              name="nameAr"
              source="nameAr"
              label="catalogue.pages.bundles.labels.nameAr"
              validate={validateArName}
            />
          </RowInfoBlock>

          <RowInfoBlock
            flex={1}
            titleKey="catalogue.pages.bundles.labels.descriptionAr"
          >
            <ReachTextEditor name="descriptionAr" />
          </RowInfoBlock>
        </Flex>
      </ResourceTab>

      <ResourceTab
        label="catalogue.pages.bundles.tabs.images"
        subscribedFields={BUNDLE_TABS_SUBSCRIBED_FIELDS.images}
      >
        <ImagesTab />
      </ResourceTab>

      {isEditPage && (
        <ResourceTab
          label="catalogue.pages.bundles.tabs.settings"
          subscribedFields={BUNDLE_TABS_SUBSCRIBED_FIELDS.settings}
        >
          <BundleSettingsTab />
        </ResourceTab>
      )}
    </ResourceTabbedForm>
  );
};

const validateCategoryId = [required()];
