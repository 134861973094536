import { styled } from '@mui/material/styles';
import { Button, TextField, Typography } from '@mui/material';

export const LoadPreviousMessagesButton = styled(Button)`
  color: #19222d;
`;

export const DateBadge = styled('span')`
  background: #464d56;
  color: #19222d;
  border-radius: 100px;
  padding: 2px 8px;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-align: center;
`;

export const OrderIdLink = styled(Typography)<{ component?: string }>`
  text-decoration: underline;
  color: #08b375;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  margin: 0;
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
`;

export const CommentAuthor = styled(Typography)<{ component?: string }>`
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  color: #72777e;
  margin: 0;
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

export const MessageInputStyled = styled(TextField)`
  margin: 0;

  &::before {
    content: none;
  }

  input {
    padding-inline: 14px;
    padding-block: 12px;
  }
`;

export const MessageInputMetaWrapper = styled('div')`
  padding-inline: 14px;
`;
