import { styled } from '@mui/material/styles';

import { ResourceDropzone } from '@UI/ResourceUI/ResourceDropzone';
import { DropzoneClassNames } from '@UI/Dropzone/styled';

const PREFIX = 'ImageUploaderDropzone';

export const ImageUploaderViewClasses = {
  dropzone: `${PREFIX}__dropzone`,
};

export const StyledDropzone = styled(ResourceDropzone, {
  name: PREFIX,
  overridesResolver: (_, styles) => styles.root,
})`
  &.${ImageUploaderViewClasses.dropzone} {
    min-height: 303px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  .${DropzoneClassNames.highlightZone} {
    padding: 24px;
  }
`;
