import { useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { formatPhone } from '@Helpers';

export const PhoneField = () => {
  const record = useRecordContext<CoreUser>();

  if (!record) return null;

  return (
    <Root fontSize={14} noWrap>
      {formatPhone(record.phone || '')}
    </Root>
  );
};

const Root = styled(Typography)`
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? `
  direction: rtl;
  unicode-bidi: bidi-override;
  `
      : ''}
`;

PhoneField.defaultProps = {
  label: 'settings.pages.users.labels.phone',
  sortBy: 'phone',
};
