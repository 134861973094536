import { styled } from '@mui/material/styles';

const PREFIX = 'UserInfoColumn';

export const UserInfoClasses = {
  phone: `${PREFIX}__phone`,
};

export const Root = styled('span')`
  .${UserInfoClasses.phone} {
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? `
            direction: rtl;
            unicode-bidi: bidi-override;
          `
        : ''}
  }
`;
