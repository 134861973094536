import { SyntheticEvent } from 'react';
import { Edit, SimpleForm, useTranslate } from 'react-admin';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';

import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { FormErrorBoundary } from '@UI';

import { AffiliateTemplateForm } from '../../components/AffiliateTemplateForm';
import { TabContent } from '../../components/TabContent';
import { AffiliatesTabs } from '../../interfaces';
import { AFFILIATES_COLUMNS } from '../../constants';

import { AffiliatesContainer } from './styled';

const ListPage = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const translate = useTranslate();

  const currentTab: AffiliatesTabs =
    (searchParams.get('tab') as AffiliatesTabs | null) ||
    AffiliatesTabs.TEMPLATE;

  const handleTabChange = (e: SyntheticEvent, tab: string): void => {
    searchParams.set('tab', tab);
    setSearchParams(searchParams);
  };

  return (
    <Edit
      id="unknown"
      sx={{ mt: 1 }}
      resource={ResourceRoutes.promotions.tapfilliate.resourcePath}
      mutationMode="pessimistic"
    >
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab
          label={translate('promotions.pages.tapfilliate.tabs.template')}
          value={AffiliatesTabs.TEMPLATE}
        />
        <Tab
          label={translate('promotions.pages.tapfilliate.tabs.affiliates')}
          value={AffiliatesTabs.AFFILIATES}
        />
      </Tabs>
      <TabContent value={currentTab}>
        <div key={AffiliatesTabs.TEMPLATE}>
          <SimpleForm reValidateMode="onChange">
            <AffiliateTemplateForm />

            <FormErrorBoundary />
          </SimpleForm>
        </div>
        <div key={AffiliatesTabs.AFFILIATES}>
          <AffiliatesContainer>
            <ResourceList<CoreAffiliate>
              rowClick={(id) => {
                navigate(
                  `${ResourceRoutes.promotions.tapfilliate.resourcePath}/affiliate/${id}`
                );

                return false;
              }}
              exporter={false}
              columns={AFFILIATES_COLUMNS}
              getItemCardCaption={() => ''}
            />
          </AffiliatesContainer>
        </div>
      </TabContent>
    </Edit>
  );
};

export default ListPage;
