import { styled } from '@mui/material/styles';
import { Drawer } from '@mui/material';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';

export const WarehousesBlacklistWrapper = styled('div')`
  width: 100%;
`;

export const ResourceListStyled = styled(ResourceList)`
  .RaBulkActionsToolbar-toolbar {
    display: none;
  }
` as typeof ResourceList;

export const AddBlacklistDrawerStyled = styled(Drawer)`
  width: 80%;
`;
