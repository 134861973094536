import { styled } from '@mui/material/styles';
import { ImageContainerStyledProps } from './interface';

export const ImageContainerStyled = styled('div')<ImageContainerStyledProps>`
  ${({ $src, $size }) => `
    width: ${$size}px;
    min-width: ${$size}px;
    height: ${$size}px;
    background-image: url(${$src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  `}
`;
