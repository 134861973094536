import { useTranslate } from 'react-admin';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { GenerateBadgesButton } from './components/GenerateBadgesButton/GenerateBadgesButton';
import {
  DEFAULT_PER_PAGE,
  DEFAULT_SORTING,
  FILTERS,
  LIST_COLUMNS,
} from './constants';

export const ListPage = () => {
  const translate = useTranslate();
  const getItemCardCaption = (record: CorePickerDto) => {
    let caption = translate('settings.pages.users.labels.mobileCaption');

    if (!record.firstName && !record.lastName) return caption;

    if (!record.firstName) {
      caption += ' ' + record.lastName;
    } else {
      caption = record.firstName;

      if (record.lastName) {
        caption += ' ' + record.lastName;
      }
    }

    return caption;
  };

  return (
    <ResourceList<CorePickerDto>
      columns={LIST_COLUMNS}
      filters={FILTERS}
      defaultSort={DEFAULT_SORTING}
      showDeleteButton={false}
      bulkActionButtons={<GenerateBadgesButton />}
      perPage={DEFAULT_PER_PAGE}
      getItemCardCaption={getItemCardCaption}
      empty={false}
    />
  );
};
