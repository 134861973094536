import { styled } from '@mui/material/styles';
import { Link } from '@mui/material';

export const Root = styled(Link)`
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? `
  direction: rtl;
  unicode-bidi: bidi-override;
  `
      : ''}
`;
