import { styled } from '@mui/material/styles';
import { Box, TextField } from '@mui/material';
import { Flex } from '@UI';

export const HeadTitle = styled(Box)`
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  flex-shrink: 0;
`;

export const WarehouseCode = styled(Flex)`
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const TextFieldStyled = styled(TextField)`
  width: 180px;
  background: #fff;
  flex-shrink: 0;
  border-radius: 4px;
  margin: 0;
`;

export const Count = styled(Box)<{ $isSinglePage?: boolean }>`
  font-size: 72px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1.2;
  ${({ $isSinglePage }) => ($isSinglePage ? 'color: #212529;' : '')}
`;

export const Title = styled(Box)<{ $isSinglePage?: boolean }>`
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 1.2;
  ${({ $isSinglePage }) => ($isSinglePage ? 'color: #212529;' : '')}
`;

export const OrderList = styled(Flex)`
  width: 80%;
  margin: 0 auto;
  flex-direction: column;
`;

export const Order = styled(Box)<{ $isSinglePage: boolean }>`
  margin: 4px 0;
  border-radius: 0.25rem;
  padding: 4px;
  font-size: 28px;
  line-height: 1.6;
  width: 100%;

  ${({ $isSinglePage }) =>
    $isSinglePage
      ? 'border: 1px solid rgba(0, 0, 0, 0.125);'
      : 'border: 1px solid rgba(255, 255, 255, 0.125);'}

  ${({ $isSinglePage }) => ($isSinglePage ? 'color: #212529;' : 'color: #fff;')}

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.9;
    }
    100% {
      opacity: 0;
    }
  }

  .shipDelaying {
    color: #ff0000;
    animation: blink 1.5s linear infinite;
  }

  .red {
    color: #c00000;
  }

  .green {
    color: #00c000;
  }

  .yellow {
    color: #cccc00;
  }
`;

export const AutocompleteWrapper = styled('div')`
  width: 450px;

  & .MuiFormLabel-root {
    color: #fff;
  }

  & .MuiInputBase-root {
    color: #fff;

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: rgba(255, 255, 255, 0.6);
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
  }

  & .MuiSvgIcon-root {
    color: #fff;
  }
`;
