import {
  Form,
  SaveButton,
  useRecordContext,
  useTranslate,
  ValidationErrorMessage,
  ArrayField,
  TextField,
  WrapperField,
  FunctionField,
  useListContext,
} from 'react-admin';
import { memo } from 'react';
import { Button } from '@mui/material';
import { FieldValues } from 'react-hook-form';

import { Flex, ResourceInputNumber, TruncatedText } from '@UI';
import { maxNum, minNum, isInteger } from '@Helpers';

import { ItemsListProps } from './interface';
import { DatagridStyled } from './styled';

const validateActualQuantity = (maxValue: string) => (value: string) => {
  if (!`${value}`) return 'ra.validation.required';

  if (isNaN(Number(value)) || isInteger()(value)) {
    return 'ra.validation.integer';
  }

  const error: ValidationErrorMessage | undefined = minNum(0)(value);

  if (error) return error;

  return maxNum(parseInt(maxValue, 10))(value);
};

const ItemsList = ({ onConfirm, onCancel }: ItemsListProps) => {
  const translate = useTranslate();
  const record = useRecordContext<CoreOrder>();

  const onSubmit = (value: FieldValues) => {
    onConfirm(
      (value as CoreOrder).items.map(({ id, actual_quantity }) => ({
        id,
        actual_quantity: !isNaN(Number(actual_quantity))
          ? Number(actual_quantity)
          : 0,
      }))
    );
  };

  return (
    <Form record={record} onSubmit={onSubmit}>
      <ArrayField source="items">
        <DatagridStyled bulkActionButtons={false}>
          <FunctionField
            label="orders.pages.labels.sku"
            render={({ sku }: CoreProductItem) => (
              <TruncatedText lines={1} variant="body2" title={sku}>
                {sku}
              </TruncatedText>
            )}
          />
          <FunctionField
            label="orders.pages.labels.name"
            render={({ name }: CoreProductItem) => (
              <TruncatedText lines={1} variant="body2" title={name}>
                {name}
              </TruncatedText>
            )}
          />
          <TextField
            source="requested_quantity"
            label="orders.pages.labels.item.requestedQuantity"
          />
          <WrapperField
            source="actual_quantity"
            label="orders.pages.labels.item.deliveredQuantity"
          >
            <ActualQuantityColumn />
          </WrapperField>
        </DatagridStyled>
      </ArrayField>

      <Flex justifyContent="center" mt={2} gap={2}>
        <Button color="secondary" variant="outlined" onClick={onCancel}>
          {translate('orders.pages.actions.cancel')}
        </Button>

        <SaveButton
          label={translate('orders.pages.actions.confirm')}
          icon={<></>}
          alwaysEnable
        />
      </Flex>
    </Form>
  );
};

const ActualQuantityColumn = memo(() => {
  const record = useRecordContext<CoreProductItem>();
  const { data } = useListContext();
  const index = data.findIndex((item) => item.id === record.id);

  const validation = validateActualQuantity(record.requested_quantity);

  return (
    <ResourceInputNumber
      name={`items.${index}.actual_quantity`}
      source={`items.${index}.actual_quantity`}
      label=""
      validate={validation}
    />
  );
});

export default ItemsList;
