import { styled } from '@mui/material/styles';
import { List, ListItemIcon, Typography } from '@mui/material';
import { MenuBodyListStyledProps, IconWrapperProps } from '../interface';

export const ListItemIconStyled = styled(ListItemIcon)`
  min-width: 40px !important;
`;

export const MenuBodyListStyled = styled(List)<MenuBodyListStyledProps>`
  box-shadow: 0 -1px 0 0 rgba(
      255,
      255,
      255,
      ${({ $sidebarIsOpen }) => ($sidebarIsOpen ? 0 : 0.2)}
    ) inset;

  a {
    transition: ${({ theme }) =>
        theme.direction === 'ltr' ? 'padding-left' : 'padding-right'}
      195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    padding-inline-start: ${({ $sidebarIsOpen }) =>
      $sidebarIsOpen ? 32 : 16}px;
  }
`;

export const IconWrapper = styled(Typography)<IconWrapperProps>`
  font-size: 0;
  ${({ $sidebarIsOpen }) =>
    !$sidebarIsOpen &&
    `
    position: absolute;
    left: -4px;
    scale: 0.6;
  `}
`;
