import React, {
  cloneElement,
  createElement,
  FC,
  isValidElement,
  useCallback,
  useEffect,
  useState,
  forwardRef,
  MouseEvent,
  ReactNode,
} from 'react';
import {
  RecordContextProvider,
  useCreatePath,
  useExpanded,
  useRecordContext,
  useResourceContext,
  useTranslate,
  useDatagridContext,
  DatagridClasses,
  ExpandRowButton,
  DatagridCell,
  DatagridRowProps,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';

const ResourceListRow: FC<DatagridRowProps> = forwardRef((props, ref) => {
  const {
    children,
    className,
    expand,
    hasBulkActions = false,
    hover = true,
    id,
    onToggleItem,
    // eslint-disable-next-line
    record: recordOverride,
    rowClick,
    selected = false,
    style,
    selectable = true,
    ...rest
  } = props;

  const context = useDatagridContext();
  const translate = useTranslate();
  const record = useRecordContext(props);
  const expandable: DatagridRowProps['expand'] | false | undefined =
    (!context?.isRowExpandable || context.isRowExpandable(record)) && expand;
  const resource = useResourceContext(props);
  const createPath = useCreatePath();
  const [expanded, toggleExpanded] = useExpanded(
    resource,
    id || '',
    context?.expandSingle
  );
  const [nbColumns, setNbColumns] = useState(() =>
    computeNbColumns(expandable, children, hasBulkActions)
  );

  useEffect(() => {
    // Fields can be hidden dynamically based on permissions;
    // The expand panel must span over the remaining columns
    // So we must recompute the number of columns to span on
    const newNbColumns = computeNbColumns(expandable, children, hasBulkActions);

    if (newNbColumns !== nbColumns) {
      setNbColumns(newNbColumns);
    }
  }, [expandable, nbColumns, children, hasBulkActions]);

  const navigate = useNavigate();

  const handleToggleExpand = useCallback(
    (event: MouseEvent) => {
      toggleExpanded();
      event.stopPropagation();
    },
    [toggleExpanded]
  );
  const handleToggleSelection = useCallback(
    (event: MouseEvent) => {
      if (!selectable) return;
      onToggleItem?.(id ?? '', event);
      event.stopPropagation();
    },
    [id, onToggleItem, selectable]
  );
  const handleClick = useCallback(
    async (event: MouseEvent) => {
      event.persist();
      const type =
        typeof rowClick === 'function'
          ? await rowClick(id ?? '', resource, record)
          : rowClick;

      if (type === false || type == null) {
        return;
      }

      if (['edit', 'show'].includes(type)) {
        navigate(createPath({ resource, id, type }));

        return;
      }

      if (type === 'expand') {
        handleToggleExpand(event);

        return;
      }

      if (type === 'toggleSelection') {
        handleToggleSelection(event);

        return;
      }

      navigate(type);
    },
    [
      rowClick,
      id,
      resource,
      record,
      navigate,
      createPath,
      handleToggleExpand,
      handleToggleSelection,
    ]
  );

  return (
    <RecordContextProvider value={record}>
      <TableRow
        ref={ref}
        className={clsx(className, {
          [DatagridClasses.expandable]: expandable,
          [DatagridClasses.selectable]: selectable,
          [DatagridClasses.clickableRow]:
            typeof rowClick === 'function' ? true : rowClick,
        })}
        key={id}
        style={style}
        hover={hover}
        onClick={handleClick}
        {...rest}
      >
        {expand && (
          <TableCell padding="none" className={DatagridClasses.expandIconCell}>
            {expandable && (
              <ExpandRowButton
                className={clsx(DatagridClasses.expandIcon, {
                  [DatagridClasses.expanded]: expanded,
                })}
                expanded={expanded}
                onClick={handleToggleExpand}
                expandContentId={`${id}-expand`}
              />
            )}
          </TableCell>
        )}
        {hasBulkActions && (
          <TableCell padding="checkbox">
            <Checkbox
              aria-label={translate('ra.action.select_row', {
                _: 'Select this row',
              })}
              color="primary"
              className={`select-item ${DatagridClasses.checkbox}`}
              checked={selected || !selectable}
              onClick={handleToggleSelection}
              disabled={!selectable}
            />
          </TableCell>
        )}
        {React.Children.map(children, (field, index) =>
          isValidElement(field) ? (
            <DatagridCell
              key={`${id}-${(field.props as any).source || index}`}
              className={clsx(
                `column-${(field.props as any).source}`,
                DatagridClasses.rowCell
              )}
              record={record}
              {...{ field, resource }}
            />
          ) : null
        )}
      </TableRow>
      {expandable && expanded && (
        <TableRow
          key={`${id}-expand`}
          id={`${id}-expand`}
          className={DatagridClasses.expandedPanel}
        >
          <TableCell colSpan={nbColumns}>
            {isValidElement(expand)
              ? cloneElement(expand, {
                  // eslint-disable-next-line
                  // @ts-ignore
                  record,
                  resource,
                  id: String(id),
                })
              : // eslint-disable-next-line
                // @ts-ignore
                createElement(expand, {
                  record,
                  resource,
                  id: String(id),
                })}
          </TableCell>
        </TableRow>
      )}
    </RecordContextProvider>
  );
});

const computeNbColumns = (
  expand: DatagridRowProps['expand'] | false | undefined,
  children: ReactNode,
  hasBulkActions: boolean
): number =>
  expand
    ? 1 + // show expand button
      (hasBulkActions ? 1 : 0) + // checkbox column
      React.Children.toArray(children).filter((child) => !!child).length // non-null children
    : 0; // we don't need to compute columns if there is no expand panel;

export default ResourceListRow;
