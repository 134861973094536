import { useState } from 'react';
import { Identifier, useTranslate } from 'react-admin';

import { ResourceListStyled } from '@Widgets/Bundles/SelectOffersPopupContent/styles';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { PRODUCT_LIST_COLUMNS } from '@Plugins/Catalogue/resources/Products/constants';

import { SelectProductModalContentProps } from './interfaces';
import { Filter } from '@Widgets/ResourceList/interface';
import { STATUS_CHOICES } from '@Plugins/Catalogue/resources/share/constants';

const SelectProductModalContent = (props: SelectProductModalContentProps) => {
  const {
    isRowSelectable,
    selectedProduct,
    onSelect,
    columns = PRODUCT_LIST_COLUMNS,
    filters = LIST_FILTERS,
    disabled,
  } = props;
  const translate = useTranslate();

  const [selectedIds, setSelectedIds] = useState<number[]>(() =>
    selectedProduct ? [selectedProduct.id] : []
  );

  const handleToggleItem = (id: number) => {
    setSelectedIds([id]);
  };

  const handleRowClick = (id: Identifier): boolean => {
    if (!isRowSelectable(Number(id)) || disabled) {
      return true;
    }

    setSelectedIds([Number(id)]);

    return true;
  };

  return (
    <ResourceListStyled<CoreProduct>
      isRowSelectable={(record) => isRowSelectable(record.id)}
      resource={ResourceRoutes.product.resourcePath}
      columns={columns}
      actions={false}
      filters={filters}
      rowClick={handleRowClick}
      onToggleItem={handleToggleItem}
      onSelectedInstanceChange={onSelect}
      selectedIds={selectedIds}
      getItemCardCaption={(record) =>
        translate('catalogue.pages.offers.labels.mobileCardCaption', {
          id: record.id,
        })
      }
      bulkActionButtons={<></>}
      heightOffset={340}
      overrideRowComponent
    />
  );
};

const LIST_FILTERS: Filter[] = [
  { source: 'search', type: 'search' },
  {
    source: 'status',
    label: 'catalogue.pages.products.labels.status',
    type: 'choose',
    choices: STATUS_CHOICES,
    emptyText: 'catalogue.pages.products.labels.allStatuses',
  },
];

export default SelectProductModalContent;
