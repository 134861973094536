import { styled } from '@mui/material/styles';
import { Create, Edit, Show, FormTab } from 'react-admin';

export const DefaultStyles = `
  .RaCreate-main, .RaEdit-main {
     margin-top: 0 !important;
  }
  
  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    position: relative;
    
    > div: first-of-type {
      flex-grow: 1;
    }
  }
  
  .MuiToolbar-root {
    position: sticky !important;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  
  .MuiTabs-scroller {
    max-width: 100vw;
  }
`;

export const CreateFromContainerStyled = styled(Create)`
  ${DefaultStyles}
`;

export const EditFromContainerStyled = styled(Edit)`
  ${DefaultStyles}
`;

export const FormTabStyled = styled(FormTab)<{ $errorColor?: string }>`
  ${({ $errorColor }) =>
    $errorColor ? `color: ${$errorColor} !important;` : ''}
`;

export const ShowFromContainerStyled = styled(Show)`
  .RaShow-main {
    margin-top: 0;
  }
`;
