import { FC, forwardRef, memo } from 'react';
import { DatagridBody } from 'react-admin';

import ResourceListRow from './ResourceListRow';
import { ResourceListRowProps } from './interfaces';

const ResourceListBody: FC<ResourceListRowProps> = forwardRef(
  ({ overrideRowComponent = false, ...props }, ref) => (
    <DatagridBody
      {...props}
      ref={ref}
      row={overrideRowComponent ? <ResourceListRow /> : undefined}
    />
  )
);

export default memo(ResourceListBody);
