import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const PREFIX = 'ResourceBackButton';

export const Root = styled(Button, {
  name: PREFIX,
  overridesResolver: (_, styles) => styles.root,
})`
  .MuiButton-startIcon {
    margin-inline-end: 8px;
    margin-inline-start: -2px;
  }
`;
