import { Button, Toolbar } from 'react-admin';
import { styled } from '@mui/material/styles';

export const ScheduleHeadStyled = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 16px;
`;

export const AddScheduleButtonStyled = styled(Button)`
  width: 100%;
  margin-top: 16px;
`;

export const FormContentStyled = styled('div')`
  padding: 0;
`;

const PREFIX = 'SlotFormToolbar';

export const SlotToolbarClasses = {
  leftCorner: `${PREFIX}__leftCorner`,
};

export const CreateModeToolbarStyled = styled(Toolbar)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  .${SlotToolbarClasses.leftCorner} {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
`;
