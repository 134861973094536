import { styled } from '@mui/material/styles';
import { DateField } from 'react-admin';

export const DateFieldStyled = styled(DateField)`
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? `
    direction: rtl;
    unicode-bidi: bidi-override;
  `
      : ''}
`;
