import {
  DatagridProps,
  DeleteWithConfirmButton,
  RaRecord,
  useListContext,
} from 'react-admin';

import { ResourceDesktopGridContentProps } from '@Widgets/ResourceList/interface';
import { DEFAULT_SORTING } from '@Widgets/ResourceList/constants';
import { ResourceDesktopGrid } from '@Widgets/ResourceList/ResourceDesktopGrid/ResourceDesktopGrid';
import { ComponentFactory } from '@Widgets/ResourceList/ComponentFactory/ComponentFactory';

import ResourceListBody from './ResourceListBody';

const ResourceDesktopGridContent = <RecordType extends RaRecord = RaRecord>(
  props: ResourceDesktopGridContentProps<RecordType>
) => {
  const {
    onSelect,
    onToggleItem,
    selectedIds,
    emptyContent,
    defaultSort = DEFAULT_SORTING,
    isRowSelectable,
    bulkActionButtons,
    isInteractive,
    rowClick,
    columns,
    showDeleteButton,
    confirmDeleteDialogContent,
    overrideRowComponent,
  } = props;

  const { isLoading } = useListContext({
    onSelect,
    onToggleItem,
    selectedIds,
  });

  return isLoading ? (
    <>{emptyContent}</>
  ) : (
    <ResourceDesktopGrid
      defaultSort={defaultSort}
      isRowSelectable={isRowSelectable}
      bulkActionButtons={bulkActionButtons}
      isInteractive={isInteractive}
      rowClick={rowClick as DatagridProps['rowClick']}
      selectedIds={selectedIds}
      onToggleItem={onToggleItem}
      onSelect={onSelect}
      body={<ResourceListBody overrideRowComponent={overrideRowComponent} />}
    >
      {columns?.map((column) => {
        const sortable = column.sortable ?? true;

        return (
          <ComponentFactory<RecordType>
            sortBy={sortable ? column.source : undefined}
            key={column.source}
            column={column}
            label={column.labelElement || column.label}
            source={column.source}
            sortable={column.sortable}
          />
        );
      })}

      {showDeleteButton && (
        <DeleteWithConfirmButton confirmContent={confirmDeleteDialogContent} />
      )}
    </ResourceDesktopGrid>
  );
};

export default ResourceDesktopGridContent;
