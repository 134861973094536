import { styled } from '@mui/material/styles';
import { Link } from '@mui/material';

export const StyledLink = styled(Link)`
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;
