import { useRecordContext, useTranslate } from 'react-admin';

const BundleStatus = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  return <>{translate(`catalogue.pages.bundles.statuses.${record.status}`)}</>;
};

export default BundleStatus;
