import { styled } from '@mui/material/styles';
import { Flex } from '@UI';
import { Card } from '@mui/material';

export const PageLayout = styled(Flex)`
  background: url(${require('./assets/login_page_bg.jpg')}) 50% no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const LoginCard = styled(Card)`
  max-width: 90vw;
  width: 440px;
  border-radius: 12px !important;
  padding: 32px;
  box-sizing: border-box;
`;
