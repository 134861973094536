import { styled } from '@mui/material/styles';

interface QuillRootProps {
  $toolbarTextColor: string;
}

const PREFIX = 'ReachTextEditor';

export const ReachTextEditorClasses = {
  editor: `${PREFIX}__editor`,
};

export const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (_, styles) => styles.root,
})<QuillRootProps>`
  width: 100%;

  .${ReachTextEditorClasses.editor} {
    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) {
      .ql-picker-label svg {
        right: 0;
        left: auto;
      }
    }

    .ql-toolbar {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .ql-picker-label {
        color: ${({ $toolbarTextColor }) => $toolbarTextColor};
      }

      svg {
        path,
        polygon,
        line,
        circle,
        polyline,
        rect {
          stroke: ${({ $toolbarTextColor }) => $toolbarTextColor};
        }
      }

      .ql-strike {
        svg {
          path {
            fill: ${({ $toolbarTextColor }) => $toolbarTextColor};
          }
        }
      }
    }

    .ql-container {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .ql-editor {
        text-align: start;
      }
    }
  }
`;
