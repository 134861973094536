import { Drawer } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, matchPath } from 'react-router-dom';

import { ListItemDrawerProps } from './interface';

export const ListItemDrawer = ({ route, children }: ListItemDrawerProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [listLocationWithFilters, setListLocationWithFilters] = useState('');
  const id = matchPath(`${route}/:id/`, location.pathname)?.params.id;
  const onCloseDrawerHandle = () =>
    navigate(`${route}${listLocationWithFilters}`);

  useEffect(() => {
    if (id) return;
    setListLocationWithFilters(`${location.search}${location.hash}`);
  }, [id, location.key]);

  return (
    <Drawer open={!!id} onClose={onCloseDrawerHandle} anchor="right">
      {children({
        isCreate: id === 'create',
        entity: id,
      })}
    </Drawer>
  );
};
