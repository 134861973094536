import { Filter, ListColumn } from '@Widgets/ResourceList/interface';

import { LogEventColumn } from './components/columns/LogEventColumn';

export const DEFAULT_DAY_OF_WEEK = 1;

export const CREATE_PAGE_DEFAULT_FIELD_VALUES: CoreWarehouse = {
  id: '',
  name: '',
  code: '',
  isActive: false,
  companyId: undefined,
  location: {
    address: '',
    googlePlaceId: '',
    latitude: '' as unknown as number,
    longitude: '' as unknown as number,
  },
  timezone: '',
  schedule: [
    {
      dayOfWeek: 1,
      intervals: [
        {
          id: 0,
          start: '00:00:00',
          end: '00:00:00',
        },
      ],
    },
    {
      dayOfWeek: 2,
      intervals: [
        {
          id: 0,
          start: '00:00:00',
          end: '00:00:00',
        },
      ],
    },
    {
      dayOfWeek: 3,
      intervals: [
        {
          id: 0,
          start: '00:00:00',
          end: '00:00:00',
        },
      ],
    },
    {
      dayOfWeek: 4,
      intervals: [
        {
          id: 0,
          start: '00:00:00',
          end: '00:00:00',
        },
      ],
    },
    {
      dayOfWeek: 5,
      intervals: [
        {
          id: 0,
          start: '00:00:00',
          end: '00:00:00',
        },
      ],
    },
    {
      dayOfWeek: 6,
      intervals: [
        {
          id: 0,
          start: '00:00:00',
          end: '00:00:00',
        },
      ],
    },
    {
      dayOfWeek: 7,
      intervals: [
        {
          id: 0,
          start: '00:00:00',
          end: '00:00:00',
        },
      ],
    },
  ],
};

export const LIST_COLUMNS: ListColumn<CoreWarehouse>[] = [
  {
    source: 'name',
    label: 'stores.warehouses.pages.labels.name',
    type: 'text',
  },
  {
    source: 'code',
    label: 'stores.warehouses.pages.labels.code',
    type: 'text',
  },
  {
    // TODO: Use "function" type for getting data from deep paths
    // @ts-ignore
    source: 'location.address',
    label: 'stores.warehouses.pages.labels.address',
    type: 'text',
    sortable: false,
  },
  {
    source: 'isActive',
    label: 'stores.warehouses.pages.labels.isActive',
    type: 'boolean',
  },
  {
    // TODO: fix source in type definition
    // @ts-ignore
    source: 'createdAt',
    label: 'stores.warehouses.pages.labels.createdAt',
    type: 'date',
    showTime: true,
  },
  {
    // TODO: fix source in type definition
    // @ts-ignore
    source: 'updatedAt',
    label: 'stores.warehouses.pages.labels.updatedAt',
    type: 'date',
    showTime: true,
  },
];

export const FILTERS: Filter[] = [{ source: 'search', type: 'search' }];

export enum ResourceMeta {
  DeliveryArea = 'DeliveryArea',
  WarehouseLog = 'WarehouseLog',
}

export const USER_LOGGER_COLUMNS: ListColumn[] = [
  {
    source: 'client',
    label: 'settings.pages.userLogger.labels.client',
    type: 'text',
    sortable: false,
  },
  {
    source: 'createdAt',
    label: 'settings.pages.userLogger.labels.createdAt',
    type: 'date',
    showTime: true,
  },
  {
    source: 'event',
    label: 'settings.pages.userLogger.labels.event',
    type: 'custom',
    sortable: false,
    customComponent: <LogEventColumn />,
  },
  {
    source: 'ip',
    label: 'settings.pages.userLogger.labels.ip',
    type: 'text',
    sortable: false,
  },
  {
    source: 'userId',
    label: 'settings.pages.userLogger.labels.userId',
    type: 'text',
    sortable: false,
  },
];
