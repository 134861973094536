import { useController } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { useTranslate } from 'react-admin';

import { useTheme } from '@mui/material/styles';
import { UIErrorTypography } from '@ROOT/layout/UI';
import 'react-quill/dist/quill.snow.css';

import { MODULES, FORMATS } from './constants';
import { ReachTextEditorProps } from './interface';
import { Root, ReachTextEditorClasses } from './styles';

export const ReachTextEditor = ({
  name,
  isRequired = false,
  ref,
  onKeyDown,
  validationRules = {},
}: ReachTextEditorProps) => {
  const theme = useTheme();
  const translate = useTranslate();
  const rules = {
    required: isRequired ? translate('ra.validation.required') : undefined,
    ...validationRules,
  };
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, rules });

  const handleChange = (val: string) => {
    onChange(val);
  };

  return (
    <Root $toolbarTextColor={theme.palette.text.primary}>
      <ReactQuill
        className={ReachTextEditorClasses.editor}
        onKeyDown={onKeyDown}
        ref={ref}
        preserveWhitespace
        value={value ?? ''}
        onChange={handleChange}
        modules={MODULES}
        formats={FORMATS}
      />

      {!!error?.message?.length && (
        <UIErrorTypography>{error?.message}</UIErrorTypography>
      )}
    </Root>
  );
};
