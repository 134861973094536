import { styled } from '@mui/material/styles';
import { Drawer } from '@mui/material';

export const DrawerStyled = styled(Drawer)<{
  $isMobile: boolean;
  $minWidth?: string;
}>`
  .MuiPaper-root {
    ${({ $isMobile, $minWidth: $minSize }) => `
        ${
          $isMobile
            ? `
                max-width: 100%;
                min-height: 80%;
                max-height: 95%;
                `
            : ''
        }
        ${!$isMobile && $minSize ? `min-width: ${$minSize};` : ''}
    `}
  }
`;
