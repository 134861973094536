import { styled } from '@mui/material/styles';
import { getResourceFieldTruncateStyles } from '../../../commonStyles';
import { AutocompleteArrayInput } from 'react-admin';

export const OptionTextStyled = styled('span')<{ truncate: number }>`
  ${({ truncate }) => getResourceFieldTruncateStyles(truncate)}
`;

export const AutocompleteArrayInputStyled = styled(AutocompleteArrayInput)<{
  truncate: number;
}>`
  .MuiChip-label {
    span {
      vertical-align: middle;
      ${({ truncate }) => getResourceFieldTruncateStyles(truncate / 2)}
    }
  }
`;
