import { useUpdate } from 'react-admin';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { ResourceAutocompleteArrayInput } from '@UI';
import { useCategoryList } from '@ROOT/hooks';

import { ReferenceListCustomColumnProps } from './interfaces';

export const AdditionalCategoriesColumn = (
  props: ReferenceListCustomColumnProps
) => {
  const { record } = props;

  const categoryList = useCategoryList();

  const formMethods = useForm({
    values: {
      categories: (record?.categories || []).map((cat) =>
        Number(cat.categoryId)
      ),
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const [update, { isLoading }] = useUpdate(
    ResourceRoutes.filters.resourcePath
  );

  const handleCategoriesUpdate = (ids: number[]) => {
    update(ResourceRoutes.filters.resourcePath, {
      id: record?.id,
      previousData: record,
      data: {
        categories: ids.map((id) => ({
          categoryId: id,
        })),
      },
    });
  };

  const handleSubmit = (values: FieldValues) => {
    handleCategoriesUpdate(values.categories || []);
  };

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        style={{ width: 500 }}
        onClick={(e) => e.stopPropagation()}
      >
        <ResourceAutocompleteArrayInput
          name="categories"
          source="categories"
          label="settings.pages.properties.labels.categories"
          choices={categoryList}
          fullWidth
          multiple
          onChange={handleCategoriesUpdate}
          disabled={isLoading}
        />
      </form>
    </FormProvider>
  );
};
