import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { FlexProps } from './interface';

export const BoxStyled = styled(Box)`
  ${({ asColumn, fullHeight, fullWidth }: FlexProps) => `
    display: flex;
    flex-direction: ${asColumn ? 'column' : 'row'};
    ${fullHeight ? 'height: 100vh;' : ''}
    ${fullWidth ? 'width: 100%;' : ''}
  `}
`;
