import { useRecordContext, useTranslate } from 'react-admin';

const PropertyTypeColumn = () => {
  const { type } = useRecordContext<CoreProperty>();
  const translate = useTranslate();

  return (
    <>
      {translate(`settings.pages.properties.labels.types.${type}`)}
    </>
  );
};

export default PropertyTypeColumn;
