import { ListSubheader } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GroupedItemStyled = styled('li')`
  padding-block: 0;
`;

export const GroupLabelStyled = styled(ListSubheader)`
  top: -8px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 28px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.05)
  );
  background: #19222d;
`;

export const GroupChildrenStyled = styled('ul')`
  padding: 0;
  & .MuiAutocomplete-option {
    padding-left: 24px;
  }
`;
