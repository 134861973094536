import { useController, useFormContext } from 'react-hook-form';
import { Switch } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import { useEffect } from 'react';

import { ResourceSwitcherProps } from './interfaces';
import { FormControlLabelStyled } from './styled';

export const ResourceSwitcher = (props: ResourceSwitcherProps) => {
  const { name, labelKey, translate = 'yes' } = props;
  const translation = useTranslate();
  const record = useRecordContext();
  const value = useController({ name });
  const { setValue } = useFormContext();
  const isChecked = (val: string | boolean): boolean => {
    if (typeof val === 'boolean') return val;

    return val === 'true';
  };

  useEffect(() => {
    if (!record || record?.[name] === undefined) return;
    setValue(name, !isChecked(record[name]), {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [record?.[name]]);

  return (
    <FormControlLabelStyled
      {...props}
      {...value.field}
      checked={isChecked(value.field.value)}
      control={<Switch disabled={props.disabled} />}
      label={
        translate === 'yes' && typeof labelKey === 'string'
          ? translation(labelKey)
          : labelKey
      }
    />
  );
};
