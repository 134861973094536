import { Layout, LayoutProps, useRefresh } from 'react-admin';

import AppBar from './AppBar/AppBar';
import Menu from './Menu/Menu';
import { useEffect } from 'react';
import pluginManager from '@PluginManager/PluginManager';
import { accessPermission } from '@Helpers';
import { useNavigate } from 'react-router';
import ThemeProvider from '../themeProvider';

export const AppContainer = (props: LayoutProps) => {
  const refresh = useRefresh();
  const navigate = useNavigate();

  if (!accessPermission.checkAccess(window.location.hash.slice(1))) {
    navigate('/');
  }

  useEffect(() => {
    if (!window.localStorage.getItem('auth.accessToken')) return;
    pluginManager.init(accessPermission.getPluginList());
    refresh();
  }, [window.localStorage.getItem('auth.accessToken')]);

  return (
    <ThemeProvider>
      <Layout {...props} appBar={AppBar} menu={Menu} />
    </ThemeProvider>
  );
};
