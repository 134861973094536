import { Typography, MenuItem } from '@mui/material';
import { SubMenuHeaderProps } from '../../interface';
import { ListItemIconStyled, IconWrapper } from '../styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useSidebarState } from 'react-admin';

export const SubMenuHeader = (props: SubMenuHeaderProps) => {
  const { dense, onClick, isOpen, name, icon } = props;
  const [open] = useSidebarState();

  return (
    <MenuItem dense={dense} onClick={onClick}>
      <ListItemIconStyled>{icon}</ListItemIconStyled>

      <Typography
        variant="inherit"
        color="textSecondary"
        width="100%"
        title={name}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {name}
      </Typography>

      <IconWrapper $sidebarIsOpen={open} color="secondary" variant="inherit">
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </IconWrapper>
    </MenuItem>
  );
};
