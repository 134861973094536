import { FormDataConsumer } from 'react-admin';
import {
  PROMOCODE_CUSTOMERS_ELIGIBILITY_LIMIT_CHOICES,
  promocodesTranslationNamespace,
} from '../../../constants';
import { RowInfoBlock, ResourceAutocompleteArrayInput } from '@UI';
import { RESOURCES_SCHEMA } from '../../../schemas/resourcesSchema';
import { getPrefixedSource } from '@Plugins/Promotions/resources/Promocodes/utils';

import { RadioButtonGroupStyled } from '../styled';

interface Props {
  sourcePrefix?: string;
}

export const CustomerEligibility = ({ sourcePrefix }: Props) => {
  return (
    <RowInfoBlock
      asColumn
      titleKey={promocodesTranslationNamespace.labels.customerEligibility}
    >
      <RadioButtonGroupStyled
        name={getPrefixedSource(
          RESOURCES_SCHEMA.customerEligibility,
          sourcePrefix
        )}
        source={getPrefixedSource(
          RESOURCES_SCHEMA.customerEligibility,
          sourcePrefix
        )}
        label=""
        defaultValue="everyone"
        choices={PROMOCODE_CUSTOMERS_ELIGIBILITY_LIMIT_CHOICES}
      />

      <FormDataConsumer>
        {({ formData }) =>
          formData.customerEligibility !== 'everyone' && (
            <ResourceAutocompleteArrayInput
              fullWidth
              choices={[]}
              source={getPrefixedSource(
                RESOURCES_SCHEMA.filters[
                  formData.customerEligibility === 'specificCustomers'
                    ? 'forCustomers'
                    : 'forSegments'
                ],
                sourcePrefix
              )}
            />
          )
        }
      </FormDataConsumer>
    </RowInfoBlock>
  );
};
