import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const StyledLocaleButton = styled(Button)<{ $isRTL: boolean }>`
  .MuiButton-startIcon {
    margin-right: 8px;
    margin-left: -7px;
  }

  .MuiButton-endIcon {
    margin-right: -4px;
    margin-left: 8px;
  }
`;
