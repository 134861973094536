import { useRecordContext, useTranslate } from 'react-admin';

const LogEventColumn = () => {
  const { event } = useRecordContext();
  const translate = useTranslate();

  return (
    <>{translate(`settings.pages.userLogger.events.${event}`, { _: event })}</>
  );
};

export default LogEventColumn;
