import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { TopToolbar, useTranslate } from 'react-admin';
import {
  apiUrl,
  OUTBOUND_ORDER_LIST_COLUMNS,
  DEFAULT_SORTING,
  FILTERS,
} from './constants';
import { UpdateListByFileButton } from '@UI';

const isCancellableItem = (record: CoreOutboundOrder) =>
  !['canceled', 'finished', 'in_progress'].includes(record.status);

const ListActions = () => (
  <TopToolbar>
    <UpdateListByFileButton
      successMessage="distributionCenter.pages.outboundOrders.messages.successImport"
      errorMessage="distributionCenter.pages.outboundOrders.errors.errorImport"
      urlOfXLSXExample={`${apiUrl}/import/example`}
    />
  </TopToolbar>
);

export const ListPage = () => {
  const translate = useTranslate();

  return (
    <>
      <ResourceList<CoreOutboundOrder>
        columns={OUTBOUND_ORDER_LIST_COLUMNS}
        filters={FILTERS}
        disableSyncWithLocation
        isRowSelectable={isCancellableItem}
        bulkActionButtons={false}
        /* bulkActionButtons={
          <BulkUpdateWithConfirmButton
            label={translate('distributionCenter.labels.cancel')}
            confirmContent=""
            confirmTitle={translate('distributionCenter.bulkCancelTitle')}
            mutationMode="pessimistic"
          />
        }*/
        empty={false}
        actions={<ListActions />}
        defaultSort={DEFAULT_SORTING}
        showDeleteButton={false}
        getItemCardCaption={(record) =>
          translate(
            'distributionCenter.pages.outboundOrders.labels.mobileCardCaption',
            {
              id: record.id,
            }
          )
        }
      />
    </>
  );
};
