import { useController } from 'react-hook-form';
import {
  ValidationErrorMessageWithArgs,
  useTranslate,
  ImageField,
  shallowEqual,
  useNotify,
} from 'react-admin';

import { Dropzone } from '@UI';
import { LocalFile, RejectFile, TemporaryFile } from '@UI/Dropzone/interfaces';

import { ResourceDropzoneProps, DropzoneFileType } from './interfaces';
import { DropzoneTypeAcceptMap, DropzoneTypedLabel } from './constants';

const ResourceDropzone = (props: ResourceDropzoneProps) => {
  const {
    source,
    validate,
    required,
    label,
    type = DropzoneFileType.FILE,
    multiple,
    onDropAccepted,
    onDropRejected,
    validateFileRemoval,
    aspectRatio,
    children,
    allowedTypes,
    maxFileRows,
    maxSize,
    className,
    error: errorProp,
  } = props;
  const translate = useTranslate();
  const notify = useNotify();

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name: source,
    rules: {
      required,
      validate: async (value, formValues) => {
        const validators = Array.isArray(validate) ? validate : [validate];

        for (const fn of validators) {
          if (!fn) {
            continue;
          }

          const errorMessage = await fn(value, formValues, props);

          if (errorMessage) {
            if (typeof errorMessage === 'object') {
              const error = errorMessage as ValidationErrorMessageWithArgs;

              return translate(error.message, error.args);
            }

            return translate(errorMessage);
          }
        }

        return undefined;
      },
    },
  });

  const files = Array.isArray(value) ? value : !value ? [] : [value];
  const preparedAllowedTypes = allowedTypes || DropzoneTypeAcceptMap[type];

  const handleFilesDrop = (
    newFiles: LocalFile[],
    rejectFiles: RejectFile[]
  ) => {
    const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles];
    const singleValue =
      !newFiles.length && rejectFiles.length > 0 ? files[0] : newFiles[0];

    onChange(multiple ? updatedFiles : singleValue ?? null);

    if (newFiles.length && onDropAccepted) {
      onDropAccepted(newFiles);
    }

    if (rejectFiles.length) {
      if (onDropRejected) {
        onDropRejected(rejectFiles);

        return;
      }

      const message = rejectFiles[0].errors
        .map((error) => error.message)
        .join('\n');

      notify(message, {
        type: 'error',
        multiLine: true,
      });
    }
  };

  const handleRemove = (file: TemporaryFile) => {
    if (multiple) {
      const filteredFiles = files.filter(
        (stateFile) => !shallowEqual(stateFile, file)
      );

      onChange(filteredFiles as any);
    } else {
      onChange(null);
    }
  };

  return (
    <Dropzone
      label={label}
      onFilesDrop={handleFilesDrop}
      onRemove={handleRemove}
      allowedTypes={preparedAllowedTypes}
      dialogActionLabel={DropzoneTypedLabel[type]}
      multiple={multiple}
      validateFileRemoval={validateFileRemoval}
      aspectRatio={aspectRatio}
      previewItems={files
        .map((item) => {
          if (typeof item === 'string') {
            return { isRemote: true, url: item, title: 'Preview' };
          }

          return item;
        })
        .filter((item) => Boolean(item?.url))}
      maxSize={maxSize}
      maxFileRows={maxFileRows}
      error={errorProp?.message ?? error?.message}
      className={className}
    >
      {children || <ImageField source="url" title="title" />}
    </Dropzone>
  );
};

export default ResourceDropzone;
