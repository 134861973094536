import { Datagrid } from 'react-admin';
import styled from 'styled-components';

export const DatagridStyled = styled(Datagrid)`
  & td.column-sku {
    width: 25%;
  }
  & td.column-name {
    width: 45%;
  }
  & td.column-requested_quantity {
    width: 15%;
  }
  & td.column-actual_quantity {
    width: 15%;
  }
`;
