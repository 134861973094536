import { FormDataConsumer } from 'react-admin';
import { FieldValues, useController } from 'react-hook-form';

import { useIsMobile } from '@Hooks/useIsMobile';
import { RowInfoBlock, Flex, ResourceInputNumber } from '@UI';
import { MoneyFormatter, minNum } from '@Helpers';
import {
  getPrefixedSource,
  getValueBySource,
} from '@Plugins/Promotions/resources/Promocodes/utils';

import {
  PROMOCODE_MIN_REQUIREMENTS_LIMIT_CHOICES,
  promocodesTranslationNamespace,
} from '../../../constants';
import { RESOURCES_SCHEMA } from '../../../schemas/resourcesSchema';

import { RadioButtonGroupStyled } from '../styled';

export const maxDigits =
  (max: number, message = 'ra.validation.maxDigits') =>
  (value: string) => {
    if (isNaN(Number(value))) {
      return;
    }

    const [intValue] = value.toString().split('.');

    return intValue.length > max ? { message, args: { max } } : undefined;
  };

const validateMinimumPurchase =
  (sourcePrefix?: string) => (value: string, values: FieldValues) => {
    const minRequirementsChoice = getValueBySource(
      values,
      'minRequirementsChoice',
      sourcePrefix
    );

    if (minRequirementsChoice === 'minimumPurchaseAmount') {
      if (!value) return 'ra.validation.required';

      return minNum(1)(value);
    }

    return undefined;
  };
const validateMinimumQuantity =
  (sourcePrefix?: string) => (value: string, values: FieldValues) => {
    const minRequirementsChoice = getValueBySource(
      values,
      'minRequirementsChoice',
      sourcePrefix
    );

    if (minRequirementsChoice === 'minimumQuantityItems') {
      if (!value) return 'ra.validation.required';

      return minNum(1)(value);
    }

    return undefined;
  };

interface Props {
  sourcePrefix?: string;
}

export const MinRequirementsBlock = ({ sourcePrefix }: Props) => {
  const isMobile = useIsMobile();
  const { field: fieldMinimumPurchase } = useController({
    name: getPrefixedSource(RESOURCES_SCHEMA.minimumPurchase, sourcePrefix),
  });
  const { field: fieldMinimumQuantity } = useController({
    name: getPrefixedSource(RESOURCES_SCHEMA.minimumQuantity, sourcePrefix),
  });
  const handleChange = () => {
    fieldMinimumPurchase.onChange('');
    fieldMinimumQuantity.onChange('');
  };

  return (
    <RowInfoBlock asColumn={isMobile} gap={5}>
      <Flex flex={1}>
        <RadioButtonGroupStyled
          onChange={handleChange}
          name={getPrefixedSource(
            RESOURCES_SCHEMA.minRequirementsChoice,
            sourcePrefix
          )}
          source={getPrefixedSource(
            RESOURCES_SCHEMA.minRequirementsChoice,
            sourcePrefix
          )}
          label={promocodesTranslationNamespace.lists.minRequirements.caption}
          choices={PROMOCODE_MIN_REQUIREMENTS_LIMIT_CHOICES}
          row={false}
          margin="none"
        />
      </Flex>

      <Flex flex={1}>
        <FormDataConsumer>
          {({ formData }) => {
            const choiceValue = getValueBySource(
              formData,
              RESOURCES_SCHEMA.minRequirementsChoice,
              sourcePrefix
            );

            if (choiceValue === 'minimumPurchaseAmount') {
              return (
                <ResourceInputNumber
                  fullWidth
                  source={getPrefixedSource(
                    RESOURCES_SCHEMA.minimumPurchase,
                    sourcePrefix
                  )}
                  label={
                    promocodesTranslationNamespace.lists.minRequirements
                      .minimumPurchaseAmount
                  }
                  prefixSymbol={MoneyFormatter.getCurrencySymbol()}
                  validate={[
                    validateMinimumPurchase(sourcePrefix),
                    maxDigits(7),
                  ]}
                />
              );
            }

            if (choiceValue === 'minimumQuantityItems') {
              return (
                <ResourceInputNumber
                  fullWidth
                  isRequired
                  isInteger
                  source={getPrefixedSource(
                    RESOURCES_SCHEMA.minimumQuantity,
                    sourcePrefix
                  )}
                  label={
                    promocodesTranslationNamespace.lists.minRequirements
                      .minimumQuantityItems
                  }
                  validate={validateMinimumQuantity(sourcePrefix)}
                />
              );
            }

            return null;
          }}
        </FormDataConsumer>
      </Flex>
    </RowInfoBlock>
  );
};
