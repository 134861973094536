/* eslint-disable */
import { ListColumn } from '@Widgets/ResourceList/interface';
import { ResourceProductMainCategory } from '../share/components/ResourceProductMainCategory';
import React from 'react';
import { NumInputWithCurrency } from '@Widgets/NumInputWithCurrency/NumInputWithCurrency';
import { UpdateParams } from 'react-admin';

import { BundleName } from './components/columns/BundleName';
import { BundleStatus } from './components/columns/BundleStatus';

export interface BundleTableOfferRow {
  id: string | number;
  name: string;
  offerId: number;
  originalOfferPrice: string;
  discount: string;
  bundleOfferPrice: string;
  placeId: number;
}

export interface BundleTableTotalRow {
  id: string | number;
  totalOriginalOffersPrice?: number;
  totalBundleOfferPrice?: number;
}

export interface BundleTablePlaceHeaderRow {
  id: string | number;
  placeId: number | string;
}

export type BundleTableRow =
  | BundleTableOfferRow
  | BundleTableTotalRow
  | BundleTablePlaceHeaderRow;

export type BundlePageData = CoreBundle & {
  // bundleTableRows: BundleTableRow[];
  // totalOriginalOffersPrice: number;
  // totalBundleOfferPrice: number;
};

export interface UpdateMeta {
  offerIdsToAdd?: number[];
  editedPlaceId?: number | string;
  addPlace?: boolean;
}

export interface UpdateBundleParamsWithMeta<Meta>
  extends UpdateParams<CorePatchBundleRequestExtended> {
  meta?: Meta;
}

export type UpdateBundleParams = UpdateBundleParamsWithMeta<UpdateMeta>;

export enum BundleTabs {
  GENERAL = 'general',
  DESCRIPTION = 'description',
  IMAGES = 'images',
  SETTINGS = 'settings',
}

export const apiUrl = `/catalog/v1/bundles`;

export const BUNDLE_LIST_COLUMNS: ListColumn<CoreBundle>[] = [
  {
    source: 'id',
    label: 'catalogue.pages.bundles.labels.id',
    type: 'text',
    sortable: true,
  },
  {
    // TODO: Use "function" type for getting data from deep paths
    // @ts-ignore
    source: 'previewImage.url',
    label: 'catalogue.pages.bundles.labels.image',
    type: 'image',
    showInLine: false,
    sortable: false,
  },
  {
    source: 'name',
    label: 'catalogue.pages.bundles.labels.name',
    type: 'custom',
    customComponent: <BundleName key="name" />,
    sortable: false,
  },
  {
    source: 'status',
    label: 'catalogue.pages.bundles.labels.status',
    type: 'custom',
    sortable: true,
    customComponent: <BundleStatus key="status" />,
  },
  {
    source: 'categoryId',
    label: 'catalogue.pages.bundles.labels.categoryId',
    type: 'custom',
    customComponent: <ResourceProductMainCategory key="categoryId" />,
    sortable: false,
  },
  {
    source: 'createdAt',
    label: 'catalogue.pages.bundles.labels.createdAt',
    type: 'date',
  },
  {
    source: 'updatedAt',
    label: 'catalogue.pages.bundles.labels.updatedAt',
    type: 'date',
  },
];

export const BUNDLE_TABLE_COLS: ListColumn<BundleTableRow>[] = [
  {
    source: 'name',
    label: 'catalogue.pages.bundles.labels.name',
    type: 'text',
    sortable: false,
  },
  {
    source: 'originalOfferPrice',
    label: 'catalogue.pages.bundles.labels.originalOfferPrice',
    type: 'custom',
    customComponent: <NumInputWithCurrency source="originalOfferPrice" />,
    sortable: false,
  },
  {
    source: 'discount',
    label: 'catalogue.pages.bundles.labels.discount',
    type: 'custom',
    customComponent: <NumInputWithCurrency source="discount" />,
    sortable: false,
  },
  {
    source: 'bundleOfferPrice',
    label: 'catalogue.pages.bundles.labels.bundleOfferPrice',
    type: 'custom',
    customComponent: <NumInputWithCurrency source="bundleOfferPrice" />,
  },
];

export const BUNDLE_TABS_SUBSCRIBED_FIELDS: Record<BundleTabs, string[]> = {
  [BundleTabs.GENERAL]: [
    'name',
    'status',
    'categoryId',
    'additionalCategoryIds',
    'sorting',
  ],
  [BundleTabs.DESCRIPTION]: ['description'],
  [BundleTabs.IMAGES]: ['previewImage', 'previewImageThumb'],
  [BundleTabs.SETTINGS]: ['bundlePlaces'],
};
