import { FormProvider, useForm } from 'react-hook-form';
import { useNotify } from 'react-admin';
import { Button } from '@mui/material';

import { Flex, Icon, Loader, Preview, RowInfoBlock } from '@UI';
import { DropzoneFileType } from '@UI/ResourceUI/ResourceDropzone/interfaces';
import { AllowedType, LocalFile } from '@UI/Dropzone/interfaces';

import { ImageUploaderViewProps } from './interface';
import { StyledDropzone, ImageUploaderViewClasses } from './styles';

export const ImageUploaderView = ({
  label,
  source,
  onUpload,
  value,
  onRemove,
  isLoading,
  accept = ['image/*'],
  maxSizeMb,
  rejectFileMessage,
  error,
}: ImageUploaderViewProps) => {
  const notify = useNotify();
  const methods = useForm({
    defaultValues: {
      [source]: value,
    },
  });

  function onRemoveImageHandler() {
    methods.reset({ [source]: undefined });
    onRemove?.(value ?? '', source);
  }

  const handleDropAccept = (localFiles: LocalFile[]) => {
    const [first] = localFiles;

    if (!first) {
      return;
    }

    onUpload?.(first.file);
    methods.reset({ [source]: undefined });
  };

  return (
    <FormProvider {...methods}>
      <Flex
        width={300}
        minHeight="236px"
        asColumn
        position="relative"
        justifyContent="stretch"
        alignItems="stretch"
      >
        {isLoading && <Loader fullHeight={false} />}

        {!value && !isLoading && (
          <StyledDropzone
            className={ImageUploaderViewClasses.dropzone}
            label={label}
            source={source}
            type={DropzoneFileType.IMAGE}
            allowedTypes={accept as AllowedType[] | undefined}
            onDropAccepted={handleDropAccept}
            maxSize={maxSizeMb}
            onDropRejected={() => {
              notify(rejectFileMessage, {
                type: 'error',
              });

              return false;
            }}
            error={error}
          />
        )}

        {!!value && !isLoading && (
          <RowInfoBlock
            titleKey={label}
            justifyContent="space-between"
            sx={{ height: '100%', m: 0 }}
          >
            <Flex
              flex={1}
              gap={1}
              justifyContent="space-between"
              alignItems="center"
              fullWidth
              asColumn
            >
              <Preview src={value} size={200} />

              <Button onClick={onRemoveImageHandler} color="error">
                <Icon type="delete" />
              </Button>
            </Flex>
          </RowInfoBlock>
        )}
      </Flex>
    </FormProvider>
  );
};
