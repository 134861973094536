import { styled } from '@mui/material/styles';
import { Flex } from '@UI';

export const IconContainerStyled = styled(Flex)<{ $rotate: number }>`
  transform: rotate(${({ $rotate }) => $rotate}deg);
`;

export const WhiteLogoBadge = styled('span')`
  padding: 2px 4px;
  border-radius: 4px;
  background-color: white;
`;
