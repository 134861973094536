import { RaRecord, useListContext } from 'react-admin';
import { ResourceMobileGrid } from '@Widgets/ResourceList/ResourceMobileGrid/ResourceMobileGrid';
import { MobileLayout } from '@Widgets/ResourceList/ResourceMobileGrid/MobileLayout/MobileLayout';

import { useIsMobile } from '@ROOT/hooks';

import { DEFAULT_SORTING } from '../constants';
import ResourceDesktopGridContent from './ResourceDesktopGridContent';
import { ResourceListContentProps } from './interfaces';
import { useEffect } from 'react';

const ResourceListContent = <RecordType extends RaRecord = RaRecord>(
  props: ResourceListContentProps<RecordType>
) => {
  const {
    columns,
    showDeleteButton,
    getItemCardCaption,
    defaultSort = DEFAULT_SORTING,
    isInteractive,
    resource,
    rowClick,
    onSelect,
    selectedIds,
    onToggleItem,
    emptyContent,
    isRowSelectable,
    bulkActionButtons,
    confirmDeleteDialogContent,
    onSelectedInstanceChange,
    overrideRowComponent,
  } = props;
  const isMobile = useIsMobile();
  const { data } = useListContext();

  const getSortingFields = (): SortingField[] => {
    if (!columns.length) return [];

    return columns
      .filter((column) => column.sortable !== false)
      .map((column) => ({
        fieldName: column.source,
        fieldTranslateKey: column.label,
      }));
  };

  useEffect(() => {
    if (!selectedIds) {
      return;
    }

    const selectedMap = selectedIds.reduce((memo, id) => {
      const targetData = data.find((item) => item.id === id);

      if (!targetData) {
        return memo;
      }

      return {
        ...memo,
        [id]: targetData,
      };
    }, {});

    onSelectedInstanceChange?.(selectedMap);
  }, [selectedIds, data]);

  return (
    <>
      {isMobile ? (
        // TODO: pass "rowClick" to mobile component too
        <ResourceMobileGrid
          fields={getSortingFields()}
          showDeleteButton={showDeleteButton}
          getItemCardCaption={getItemCardCaption}
          defaultField={defaultSort.field}
          isInteractive={isInteractive}
          resource={resource}
          rowClick={rowClick}
        >
          {() => <MobileLayout<RecordType> columns={columns} />}
        </ResourceMobileGrid>
      ) : (
        <ResourceDesktopGridContent
          onSelect={onSelect}
          onToggleItem={onToggleItem}
          selectedIds={selectedIds}
          emptyContent={emptyContent}
          defaultSort={defaultSort}
          isRowSelectable={isRowSelectable}
          bulkActionButtons={bulkActionButtons}
          isInteractive={isInteractive}
          rowClick={rowClick}
          columns={columns}
          showDeleteButton={showDeleteButton}
          confirmDeleteDialogContent={confirmDeleteDialogContent}
          overrideRowComponent={overrideRowComponent}
        />
      )}
    </>
  );
};

export default ResourceListContent;
